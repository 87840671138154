<template>
    <div>
        <component :is="components[ search_filter.fields.mobile ? 'list' : search_filter.view_type]"  :nearby="nearby_businesses"  :results_message="results_message" :results="sorted_results" v-on:toggle-favourite="toggleFavourite" :show-skeleton="show_skeleton" :is-logged-in="is_logged_in"></component>
        <div class="flex justify-center" v-if="show_load_more">
            <a role="button" @click="queryAvailability(meta.current_page + 1)" class="text-center btn btn--light-grey">Load more</a>
        </div>
    </div>
</template>

<script>
import { AVAILABILITY_SEARCH, TOGGLE_FAVOURITE } from '@js/endpoints';
import { mapState, mapActions } from 'vuex';
import qs from 'qs';
import moment from 'moment';
export default {
    data() {
        return {
            results: null,
            meta: null,
            components: {
                map: () => import(/* webpackChunkName: "components/MapType" */ "@js/components/search/MapType.vue"),
                list: () => import(/* webpackChunkName: "components/ListType" */ "@js/components/search/ListType.vue"),
            },
            show_skeleton: false,
            is_logged_in: false,
            nearby_businesses: [],
            dont_search: false
        }
    },

    props: {
        type: {
            type: String, //'list' or 'map'
            default: 'list'
        }
    },

    computed: {
        ...mapState({
            search_filter: 'search_filter',
            industries_list: 'industries',
            services_list: 'services',
            search_adjustments: 'search_adjustments',
            is_booking: 'is_booking',
        }),

        show_load_more() {
            return this.meta && this.meta.current_page < this.meta.last_page ? true : false;
        },

        results_message() {
            if (this.sorted_results && this.sorted_results.length < 1) {
                return "That search didn't find any results, try these instead";
            } else return null;
        },

        sorted_results() {
            return (this.results || []).sort((a, b)=> {
                let a_start = moment(a.availability_start);
                let b_start = moment(b.availability_start);
                if (a_start.isBefore(b_start)) return -1;
                else if (a_start.isAfter(b_start)) return 1;
                else return 0;
            })
        }
    },

    methods: {
        ...mapActions(['checkIfAuthenticated', 'showMobileFilters']),

        queryAvailability(page = 1, concat = true) {
            this.show_skeleton = true;
            if (this.is_booking || this.$store.state.availability_drawer.visibility ||this.$store.state.service_drawer.visibility || this.$store.state.booker.visibility)  return;
            axios.get(`${AVAILABILITY_SEARCH}?page=${page}`, { params: this.search_filter.fields, paramsSerializer: params => { return qs.stringify(params)} }).then(res => {
                if (res.data.meta.search_adjustments) {
                    this.dont_search = true;
                    this.$store.commit('mutateSearchAdjustments', res.data.meta.search_adjustments);
                    this.dont_search = false;
                } 
                this.nearby_businesses = res.data.meta.nearby_businesses.filter(business => !res.data.data.find(a => a.business.id === business.id )).slice(0,12-res.data.data.length).sort((a, b) => {
                    if (a.distance < b.distance) return -1;
                    else if (a.distance > b.distance) return 1;
                    else return 0;
                });
                // else this.search_adjustments = {};
                this.results = !this.results || !concat ? res.data.data : this.results.concat(res.data.data);
                this.meta = res.data.meta;
                this.$nextTick(() => {
                    this.show_skeleton = false;
                })
            }).finally(() => {
                this.show_skeleton = false;
            });
        },

        async fakeSkeleton() {
            this.show_skeleton = true;
            setTimeout(() => {
                this.show_skeleton = false;
                return Promise.resolve(true);
            }, 800);
        },

        toggleFavourite(business_id) {
            axios.post(TOGGLE_FAVOURITE, {
                business_id
            }).then(res => {
                this.results = this.results.map(item => {
                    if(item.business.id == business_id) {
                        item.favourite = !item.favourite;
                    }
                    return item;
                });
                this.nearby_businesses = this.nearby_businesses.map(business => {
                    if (business.id  === business_id) business.favourite = !business.favourite;

                    return business;
                });
            });
        }
    },

    mounted() {
        this.queryAvailability();
        this.checkIfAuthenticated().then(value => {
            this.is_logged_in = value ? true : false;
        });
    },

    watch: {
        'search_filter.fields': {
            deep: true,
            handler() {
                if (!this.$store.state.search_visibility && !this.dont_search) {
                    this.queryAvailability(1, false);
                }
            }
        }
    }
}
</script>
