<template>
   <div class="flex-wrap lg:flex-no-wrap shadow-lg-allsides md:mb-5 md:flex" :class="(index == active_result_index ? 'c-result--active flex absolute bottom-0 md:static z-50' : 'hidden')" :ref="'result' + index" :key="index">
        <template v-if="availability">
            <div class="w-full lg:w-40 bg-center bg-cover bg-no-repeat relative flex-shrink-0 flex-grow-0 hidden md:block" :style="business_image_style">
                <button v-if="isLoggedIn" class="absolute top-0 left-0 p-1 text-white" @click="$emit('toggle-favourite', availability.business.id)" :title="(availability.favourite ? 'Unmark' : 'Mark') + ' as favourite'"><i class="fa-heart" :class="availability.favourite ? 'fas' : 'fal'"></i></button>
            </div>
            <div class="flex flex-wrap items-center justify-between flex-shrink flex-grow pt-6 md:p-6">
                <div class="pr-6 mb-4 xl:mb-0 px-6 md:px-0 flex items-center justify-between md:block w-full md:w-auto">
                    <div>
                        <span class="label">{{ availability.day_word }}</span>
                        <span class="text-copper-400 font-poynter font-bold text-2xl -mt-1 block">{{ formatTime(availability.availability_start) }} - {{ formatTime(availability.availability_end) }}</span>
                        <p class="mb-0 text-base">
                            <span role="button" class="font-semibold text-grey-1000 mr-1">{{ availability.business.name }}</span>
                            <span class="inline-block mr-1">{{ availability.business.address_line_4 }}</span>
                            <!-- <span v-if="availability.business.rating" class="text-grey-1000 font-poynter text-base mr-1"><i class="fas fa-star text-sm relative mr-1" style="top:-1px;"></i>{{ availability.business.rating}}</span> -->
                        </p>
                    </div>
                    <div class="md:hidden ">
                        <span class="italic  text-sm pt-2 pb-1 mr-1">
                            {{services_by_provider.length}} {{services_by_provider.length > 1 ? 'Services' : 'Service'}} Available From 
                        </span>

                        <span class="font-poynter text-grey-1000 text-2xl"> {{ availability.cost_from }}</span>
                    </div>
                </div>
                <div class="w-full xl:w-auto flex-grow-0 flex-shrink-0 flex md:justify-end justify-end flex-wrap text-center">
                    <span class="flex flex-row text-right justify-end">
                        <span class="italic hidden md:inline-block text-sm pt-2 pb-1 mr-1">
                            {{services_by_provider.length}} {{services_by_provider.length > 1 ? 'Services' : 'Service'}} Available From
                        </span>
                        <div class="font-poynter mr-3 hidden md:inline-block"><br class="block md:hidden"> <span class="text-grey-1000 text-2xl">{{ availability.cost_from }}</span></div>
                    </span>
                    <div class="flex items-center justify-center flex-grow flex-shrink bg-grey-400 md:hidden text-white uppercase font-bold">
                        <span class="md:hidden" @click="initQuickLook(availability.business.id)">Info</span>
                    </div>
                    <div class="flex-grow flex-shrink text-center md:text-right justify-end bg-teal-400 md:bg-transparent">
                        <span class="btn btn--teal" @click="bookClicked(availability.availability_id)">
                            {{ 
                                ((availability.more_times || (services_by_provider.length > 1 && !search_filter.fields.service_id) )? `See ${( services_by_provider.length > 1  && !search_filter.fields.service_id) ?  'Services' : 'Times'} & Book` : "Book") 
                            }}
                        </span>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="business">
            <div class="w-full lg:w-40 bg-center bg-cover bg-no-repeat relative flex-shrink-0 flex-grow-0 hidden md:block" :style="business_image_style">
                <button v-if="isLoggedIn" class="absolute top-0 left-0 p-1 text-white" @click="$emit('toggle-favourite', business.id)" :title="(business.favourite ? 'Unmark' : 'Mark') + ' as favourite'"><i class="fa-heart" :class="business.favourite ? 'fas' : 'fal'"></i></button>
            </div>
            <div class="flex flex-wrap items-center justify-between flex-shrink flex-grow pt-6 md:p-6">
                <div class="pr-6 mb-4 xl:mb-0 px-6 md:px-0 flex items-center justify-between md:block w-full md:w-auto">
                    <div>
                        <span v-if="business.services.length > 0" class="text-copper-400 font-poynter font-bold text-2xl -mt-1 block">Recently Available</span>
                        <p class="mb-0 text-base">
                            <span role="button" class="font-semibold text-grey-1000 mr-1">{{ business.name }}</span>
                            <span class="inline-block mr-1">{{ business.address_line_4 }}</span>
                            <!-- <span v-if="availability.business.rating" class="text-grey-1000 font-poynter text-base mr-1"><i class="fas fa-star text-sm relative mr-1" style="top:-1px;"></i>{{ availability.business.rating}}</span> -->
                        </p>
                    </div>
                    <div v-if="business.services.length > 0"  class="md:hidden ">
                        <span class="italic  text-sm pt-2 pb-1 mr-1">
                            {{business.services.length}} {{business.services.length > 1 ? 'Services' : 'Service'}} Available From 
                        </span>

                        <span class="font-poynter text-grey-1000 text-2xl"> {{ toCurrency(cheapest)}}</span>
                    </div>
                </div>
                <div  class="w-full xl:w-auto flex-grow-0 flex-shrink-0 flex md:justify-end justify-end flex-wrap text-center">
                    <span v-if="business.services.length > 0" class="flex flex-row text-right justify-end">
                        <span class="italic hidden md:inline-block text-sm pt-2 pb-1 mr-1">
                            {{business.services.length}} {{business.services.length > 1 ? 'Services' : 'Service'}} Available From
                        </span>
                        <div class="font-poynter mr-3 hidden md:inline-block"><br class="block md:hidden"> <span class="text-grey-1000 text-2xl">{{ toCurrency(cheapest) }}</span></div>
                    </span>
    
                    <div class="flex-grow flex-shrink text-center md:text-right justify-end bg-teal-400 md:bg-transparent">
                        <span class="btn btn--teal" @click="initQuickLook(business.id)">
                            View Salon
                        </span>
                    </div>
                </div>
            </div>
        </template>
        
    </div>
</template>
<script>

import { mapState, mapActions, mapMutations } from 'vuex';

export default {

    props: {
        index: {},
        active_result_index: {},
        availability: {
            type: Object,
            default: null
        },

        business: {},

        isLoggedIn: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapState([
            'search_filter'
        ]),
        background_image() {
	    return `${this.availability?.business?.image || this.business?.image || 'images/salon_images/business-coming-soon.jpg'}`
        },
        cheapest() {
            return this.business?.services.sort((s1, s2) => {
                if (s1.cost > s2.cost) return 1;
                if (s1.cost < s2.cost) return -1;
                return 0;
            })[0]?.cost;
        },
        business_image_style() {
            return {
                'background-image': `url('${this.availability?.business?.image || this.business?.image || 'images/salon_images/business-coming-soon.jpg'}')`,
                // 'padding-top' :'75%',
                'min-height': '125px'
            }
        },
        services_by_provider() {
            if (this.business) return null;
            return this.availability.services.map(service => ({...service, duration: service.pivot.time_required, cost: service.pivot.cost}));

            return Object.entries(this.availability.services).map(([service_name, providers]) => {
                let provider_array = Object.values(providers);
                return {
                    name: service_name,
                    cheapest: provider_array.map(provider => {
                        return provider.reduce((cost, curProvider) => {
                            return cost < curProvider.cost ? cost : curProvider.cost;
                        }, provider[0].cost);
                    }).reduce((cost, prov ) => (cost < prov? cost : prov), 1000000000000 ),
                    duration: provider_array[0]?.[0].pivot? (provider_array[0][0].pivot?.time_required) : provider_array[0][0],
                }
            }).sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
        },
        services() {
            return this.services_by_provider.map()
        }
    },

    mounted(){

    },

    methods: {
        ...mapActions(['initBooking','initQuickLook', 'initAvailabilityDrawer', 'initServiceDrawer', 'showLoginForm']),
        ...mapMutations(['mutateSearchFilterFieldsServiceId']),
        
        bookClicked() {
            if (!this.isLoggedIn) {
                this.showLoginForm()

            } else {
                let hasMultipleServices = this.services_by_provider.length > 1 && !this.search_filter.fields.service_id;
                let hasMultipleTimes = this.availability.more_times;
                if (!hasMultipleServices && !this.search_filter.fields.service_id){
                    this.mutateSearchFilterFieldsServiceId(this.services_by_provider[0].id);
                }
                if (hasMultipleTimes) {
                    this.initAvailabilityDrawer(this.availability.business.id) 
                } 
                else {
                    if (hasMultipleServices) {
                        this.initServiceDrawer(this.availability);
                    } else {
                        this.initBooking({availability_id: this.availability.availability_id});
                    }
                }
            }
        
        },

        // formatDuration(duration) {
        //     let regex = /(\d\d):(\d\d):(\d\d)/;
        //     let matches = duration.match(regex);
        //     // console.log(matches);
        //     let [all, hours, minutes, seconds] = matches;
        //     let output = "";
        //     if (hours) {
        //         if (minutes ) return `${parseInt(hours)}hrs ${parseInt(minutes)}mins`
        //         else return `${parseInt(hours)}hrs`;
        //     } else if (parseInt(minutes)) {
        //         return `${parseInt(minutes)}mins`
        //     }
        //     else return "";

        // },
    },

}
</script>
<style lang="scss" scoped>

.c-salon-preview-card {
    transition: 0.4s ease;
}

.c-salon-preview-card > div {
    transition: 0.4s ease;
}

.c-salon-preview-card:hover {
    transform: scale(1.05);
    z-index: 1;
}

.c-salon-preview-card:hover > div {
    box-shadow: 0 0 10px 6px rgba(169, 169, 169, 0.3);
}

.favourite-btn {
    @apply absolute text-white;
    top: 1rem;
    right: 1rem;

    &:focus {
        outline: none;
    }

    &::before {
        @apply absolute top-0 text-white text-xs capitalize bg-black p-1 pointer-events-none whitespace-no-wrap opacity-0;
        content: "Add to favourites";
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -100%);
        border-radius: 5px;
        transition: 0.4s ease;
    }

    &--active {
        &::before {
            content: "Remove from favourites";
        }
    }

    &:hover {
        &::before {
            @apply opacity-100;
        }
    }
}



.text-102 {
    color: rgb(102, 102, 102);
}

.border-bottom:not(:last-of-type) {
    border-bottom: 1px solid lightgrey;
}

    .c-result--active {
        @apply bg-white;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 2rem);

        @screen md {
            @apply bg-copper-400 w-full;
            left: 0;
            transform: none;
        }
    }

    .c-result--active p,
    .c-result--active a,
    .c-result--active span:not(.btn),
    .c-result--active i {

        @screen md {
            color: #ffffff!important;
        }
    }

    .c-map {
        height: 70vh;
        @screen lg {
            max-height: 100vh;

        }
        @screen md {
            position: sticky;
        }
        top: 0px;
    }
</style>
