var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        _vm.components[
          _vm.search_filter.fields.mobile ? "list" : _vm.search_filter.view_type
        ],
        {
          tag: "component",
          attrs: {
            nearby: _vm.nearby_businesses,
            results_message: _vm.results_message,
            results: _vm.sorted_results,
            "show-skeleton": _vm.show_skeleton,
            "is-logged-in": _vm.is_logged_in
          },
          on: { "toggle-favourite": _vm.toggleFavourite }
        }
      ),
      _vm._v(" "),
      _vm.show_load_more
        ? _c("div", { staticClass: "flex justify-center" }, [
            _c(
              "a",
              {
                staticClass: "text-center btn btn--light-grey",
                attrs: { role: "button" },
                on: {
                  click: function($event) {
                    return _vm.queryAvailability(_vm.meta.current_page + 1)
                  }
                }
              },
              [_vm._v("Load more")]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }