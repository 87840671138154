var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: function() {
            return _vm.mutateOverlayVisibility(false)
          },
          expression: "() => mutateOverlayVisibility(false)"
        }
      ],
      staticStyle: {
        "padding-left": "0",
        "padding-right": "0",
        "padding-bottom": "0"
      }
    },
    [
      _c("h1", { staticClass: "text-center" }, [_vm._v("Login")]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mb-4 pl-4 pr-4",
          attrs: { method: "post" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.doLogin($event)
            }
          }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email"
              }
            ],
            staticClass: "c-form__input w-full",
            attrs: {
              type: "email",
              name: "email",
              placeholder: "Email Address"
            },
            domProps: { value: _vm.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.email
            ? _c("span", { staticClass: "c-form__inline-error" }, [
                _vm._v(_vm._s(_vm.errors.email[0]))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password"
              }
            ],
            staticClass: "c-form__input w-full",
            attrs: {
              type: "password",
              name: "password",
              placeholder: "Password"
            },
            domProps: { value: _vm.password },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.password
            ? _c("span", { staticClass: "c-form__inline-error" }, [
                _vm._v(_vm._s(_vm.errors.password[0]))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("button", { staticClass: "btn btn--teal text-grey-1000 w-full" }, [
            _vm._v(_vm._s(_vm.login_label))
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-center mb-10 pl-4 pr-4" }, [
        _c(
          "a",
          {
            staticClass: "cursor-pointer inline-block text-base mb-6",
            attrs: { role: "button" },
            on: { click: _vm.showResetPassword }
          },
          [_vm._v("Forgotten Your Password?")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-center bg-grey-200 p-4 rounded-b" }, [
        _c(
          "a",
          {
            staticClass:
              "block uppercase text-grey-1000 font-semibold text-base",
            attrs: { href: _vm.params.links.signup }
          },
          [_vm._v("Not a member? Sign up here")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }