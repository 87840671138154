<template>
    <div class="tabs-wrapper">

        <div v-for="(option, index) in extracted_industries" :key="index" class="c-horizontal-tab">
            <div :class="{ 'active' : option.title == active_parent }" @click="active_parent = option.title" class="c-horizontal-tab__title" role="button">
                <div class="c-rounded-icon c-rounded-icon--small mr-3">
                    <img v-if="option.icon" :src="option.icon" class="c-rounded-icon__icon" style="max-width:70%" />

                </div>
                <p class="text-black mb-0 font-poynter font-bold">{{ option.title }}</p>
            </div>

            <div v-if="option.title == active_parent" class="c-horizontal-tab__content">
                <ul class="mb-0 -mx-3 flex flex-wrap">
                    <li v-for="(service, i) in options.filter(option => option.industry == active_parent)" :key="i" class="w-full md:w-6/12 lg:w-4/12 font-normal text-grey-1000 px-3 mb-1">{{ service.name }}</li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
import { BUSINESS_SERVICES } from '@js/endpoints';
export default {

    data() {
        return {
            active_parent: null,
            options: []
        }
    },

    props: {
        businessId: {
            required: true,
            type: Number
        }
    },


    mounted() {
        this.getServices();
    },

    watch: {
        extracted_industries() {
            this.active_parent = this.extracted_industries[0].title;
        }
    },

    computed: {
        extracted_industries() {
            return [...new Set(this.options.map(item => { return JSON.stringify({ title: item.industry, icon: item.meta.industry_icon }) }))].map(item => JSON.parse(item));
        }
    },

    methods: {
        getServices() {
            axios.get(BUSINESS_SERVICES(this.businessId)).then(res => {
                this.options = res.data.data;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.tabs-wrapper {
    @apply bg-white;
    border-radius: 1.50rem;
    box-shadow: 0 0 5px 5px rgba(169, 169, 169, 0.3);
}

.c-horizontal-tab {
    &:not(:last-of-type) {
        @apply mb-1;
    }

    &:first-of-type {
        .c-horizontal-tab__title {
            border-top-left-radius: 1.50rem;
            border-top-right-radius: 1.50rem;
        }
    }

    &:last-of-type {
        .c-horizontal-tab__title,
        .c-horizontal-tab__content {
            border-bottom-left-radius: 1.50rem;
            border-bottom-right-radius: 1.50rem;
        }
    }

    &__title {
        @apply flex items-center bg-grey-200 py-4;
        transition: 0.4s ease;

        &.active {
            @apply py-6 bg-white cursor-default;
        }
    }

    &__title,
    &__content {
        @apply px-8;
    }

    &__content {
        @apply py-6 pt-0 text-grey-600 text-base;
    }
}
</style>
