import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
import VueCookies from "vue-cookies";
import Storage from './storage';
Vue.use(Vuex, VueCookies);
import {
    LOGIN_PATH,
    RESERVE_AVAILABILITY,
    UNRESERVE_AVAILABILITY,
    CAN_BOOK,
    PASSWORD_RESET_PATH,
    PASSWORD_REQUEST_PATH,
    SIGNUP_PATH
} from "@js/endpoints";
export const store = new Vuex.Store({
    state: {
        is_booking: false,
        components: {
            loginForm: () =>
                import(/* webpackChunkName: "components/LoginForm" */ "@js/components/LoginForm"),
            resetPassword: () =>
                import(/* webpackChunkName: "components/ResetPassword" */ "@js/components/ResetPassword"),
            vimeoVideo: () =>
                import(/* webpackChunkName: "components/VimeoVideo" */ "@js/components/VimeoVideo"),
            appAvailablePopup: () =>
                import(/* webpackChunkName: "components/AppAvailablePopup" */ "@js/components/AppAvailablePopup"),
            rightPlacePopup: () =>
                import(/* webpackChunkName: "components/AppAvailablePopup" */ "@js/components/RightPlacePopup"),
            genericModal: () =>
                import(/* webpackChunkName: "components/GenericModal" */ "@js/components/GenericModal"),
            mobileFilters: () =>
                import(/* webpackChunkName: "components/MobileFilters" */ "@js/components/MobileFilters"),
            appointmentCancel: () =>
                import(/* webpackChunkName: "components/appointmentCancel" */ "@js/components/AppointmentCancel"),
            demoRequest: () =>
                import(/* webpackChunkName: "components/demoRequest" */ "@js/components/DemoRequest"),
            suspendedPopup: () =>
                import(/* webpackChunkName: "components/suspendedPopup" */ "@js/components/SuspendedPopup"),

            blogSingleGallery: () =>
                import(/* webpackChunkName: "components/BlogSingleGallery" */ "@js/components/blog/BlogSingleGallery")
        },

        component_params: {
            loginForm: {
                endpoint: LOGIN_PATH,
                links: {
                    signup: SIGNUP_PATH,
                    passwordReset: PASSWORD_REQUEST_PATH
                }
            },

            resetPassword: {
                endpoint: PASSWORD_RESET_PATH
            },

            vimeoVideo: {
                iframeSrc: null
            },

            genericModal: {
                title: null,
                body: null
            },

            appointmentCancel: {
                id: null,
                service_name: null,
                service_provider: null,
                business_name: null
            }
        },

        overlay: {
            visibility: false,
            modal: {
                content: null,
                modifierClass: null
            }
        },

        booker: {
            visibility: Storage.get("booking") ? true : false,
            details: Storage.get("booking"),
            selected: Storage.get("booking_selected") || {
                availability_id: null,
                service: null,
                time: null,
                stage: "booking" // ['booking','confirming','confirmed'] (possibly more added)
            },

            appointment: {},

            preselected: {
                service: null
            }
        },

        availability_drawer: {
            visibility: false,
            business_id: null
        },


        service_drawer: {
            visibility: false,
            business_id: null,
            availability: null
        },

        quick_look_drawer: {
            visibility: false,
            type: "business",
            id: null
        },

        filter_drawer: {
            visibility: false
        },

        blog_single_gallery: {
            visibility: false,
            slider: {
                title: null,
                slides: []
            }
        },

        misc: {
            authenticated: false,
            accordionActive: 0
        },

        search_filter: {
            fields: Storage.get("search_filter") || {
                mobile: 0,
                times: [],
                service_id: null,
                industry_id: null,
                // service_id: 99, //Catch up
                dates: [],
                location: {
                    id: null,
                    name: "All",
                    lat: null,
                    lng: null
                }
            },

            options: {
                mobile: [
                    {
                        label: "In Salon",
                        value: 0
                    },

                    {
                        label: "My Home",
                        value: 1
                    }
                ],

                times: [
                    {
                        label: "Morning",
                        value: "Morning"
                    },

                    {
                        label: "Afternoon",
                        value: "Afternoon"
                    },

                    {
                        label: "Evening",
                        value: "Evening"
                    }
                ]
            },
            view_type: "list",
            triggerable: false,
            submit: false
        },

        active_result_index: null,
        search_visibility: false,
        menu: {
           visible: false,
           type: 'main'
        },
        services: [],
        industries: [],
        search_adjustments: {},


    },

    getters: {
        adjusted_search_fields(state) {
            let adjusted_fields = {};
            let fields = Object.keys(state.search_filter.fields).map(field => {
                if (!state.search_adjustments[field]) {
                    adjusted_fields[field] = state.search_filter.fields[field];
                } else {
                    if (state.search_filter.fields[field] instanceof Array) {
                        adjusted_fields[field] = [];
                    }
                    else if (state.search_filter.fields[field] instanceof Object) {
                        adjusted_fields[field] = {};
                    }
                    else {
                        adjusted_fields[field] = null;
                    }
                }
            });
            return adjusted_fields;
        }
    },

    mutations: {
        mutateOverlayVisibility(state, payload = null) {
            payload == null
                ? (state.overlay.visibility = !state.overlay.visibility)
                : (state.overlay.visibility = payload);
        },

        mutateOverlayModalContent(state, payload) {
            state.overlay.modal.content = state.components[payload] || null;
        },

        mutateOverlayModalModifierClass(state, payload) {
            state.overlay.modal.modifierClass = payload;
        },

        mutateComponentParamsLoginForm(state, payload) {
            payload ? (state.component_params.loginForm = payload) : null;
        },

        mutateComponentParamsResetPassword(state, payload) {
            payload ? (state.component_params.resetPassword = payload) : null;
        },

        mutateComponentParamsVimeoVideo(state, payload) {
            payload ? (state.component_params.vimeoVideo = payload) : null;
        },

        mutateComponentParamsGenericModal(state, payload) {
            payload ? (state.component_params.genericModal = payload) : null;
        },

        mutateComponentParamsAppointmentCancel(state, payload) {
            payload
                ? (state.component_params.appointmentCancel = payload)
                : null;
        },

        mutateBookerVisibility(state, payload) {
            state.booker.visibility = payload;
        },

        mutateBookerDetails(state, payload) {
            state.booker.details = payload;
        },

        mutateBookerSelected(state, payload) {
            state.booker.selected = payload;
        },

        mutateBookerSelectedStage(state, payload) {
            state.booker.selected.stage = payload;
        },

        mutateBookerSelectedAvailabilityId(state, payload) {
            state.booker.selected.availability_id = payload;
        },

        mutateMiscAuthenticated(state, payload) {
            state.misc.authenticated = payload;
        },

        mutateAvailabilityDrawerVisibility(state, payload) {
            state.availability_drawer.visibility = payload;
        },

        mutateAvailabilityDrawerBusinessId(state, payload) {
            state.availability_drawer.business_id = payload;
        },


        mutateServiceDrawerVisibility(state, payload) {
            state.service_drawer.visibility = payload;
        },

        mutateServiceDrawerBusinessId(state, payload) {
            state.service_drawer.business_id = payload;
        },
        mutateServiceDrawerAvailability(state, payload) {
            state.service_drawer.availability = payload;
        },


        mutateBookerPreselectedService(state, payload) {
            state.booker.preselected.service = null;
            if (payload) {
                setTimeout(() => {
                    state.booker.preselected.service = payload;
                }, 5);
            }
        },

        mutateSearchFilterFieldsServiceId(state, payload) {
            state.search_filter.fields.service_id = payload;
        },

        mutateQuickLookDrawerVisibility(state, payload) {
            state.quick_look_drawer.visibility = payload;
        },

        mutateQuickLookDrawerId(state, payload) {
            state.quick_look_drawer.id = payload;
        },

        mutateFilterDrawerVisibility(state, payload) {
            state.filter_drawer.visibility = payload;
        },

        mutateActiveAccordionIndex(state, payload) {
            state.misc.accordionActive = payload;
        },

        mutateActiveResultIndex(state, payload) {
            state.active_result_index = payload;
        },

        mutateSearchVisibility(state, payload) {
            state.search_visibility = payload;
        },

        mutateMenu(state, payload) {
            state.menu = payload;
        },

        mutateBlogSingleGalleryVisibility(state, payload) {
            state.blog_single_gallery.visibility = payload;
        },

        mutateBlogSingleGallerySlider(state, payload) {
            state.blog_single_gallery.slider = payload;
        },
        mutateSuspendedPopupVisibility(state, payload) {
            state.suspendedPopup.visibility = payload;
        },

        mutateServices(state, payload) {
            Vue.set(state, 'services', payload);
        },

        mutateIndustries(state, payload) {
            Vue.set(state, 'industries', payload);
        },

        mutateSearchAdjustments(state, payload) {
            Vue.set(state, 'search_adjustments', payload);
            Object.keys(payload).forEach(param => {
                let val = state.search_filter.fields[param];
                let default_value = val instanceof Array ? [] : val instanceof Object ? {} : null;
                Vue.set(state.search_filter.fields, param, default_value);
            })
        },

        mutateIsBooking(state, value) {
            Vue.set(state, 'is_booking', value);
        }
    },

    actions: {
        cancelReservation(context) {
            axios
                .delete(
                    `${UNRESERVE_AVAILABILITY}/${$cookies.get("visitor_id")}`
                )
                .then(res => {
                    Storage.remove("booking");
                    Storage.remove("booking_selected");
                    context.commit(
                        "mutateBookerVisibility",
                        Storage.get("booking") ? true : false
                    );

                    context.commit("mutateBookerSelected", {
                        availability_id: null,
                        service: null,
                        time: null,
                        stage: "booking" // ['booking','confirming','confirmed'] (possibly more added)
                    });

                    context.commit(
                        "mutateBookerDetails",
                        Storage.get("booking")
                    );
                });
        },


        async initBooking(context, {availability_id, service_id = null}) {

            

            if (await context.dispatch('checkIfAuthenticated')) {
                axios.put(RESERVE_AVAILABILITY, {
                    availability_id,
                    visitor_id: $cookies.get('visitor_id')
                }).then(res => {
                    res.data.data.provider.business.services = [];
                    Storage.set('booking', res.data.data, res.data.data.expires_at);
                    $cookies.set('booking', res.data.data, moment.duration(moment(res.data.data.expires_at).diff()).asSeconds());
                    // context.commit('mutateSearchFilterFieldsServiceId', service_id);
                    context.commit('mutateBookerVisibility',true);
                    context.commit('mutateAvailabilityDrawerVisibility',false);
                    context.commit('mutateServiceDrawerVisibility',false);
                    context.commit('mutateBookerDetails',Storage.get('booking'));
                    context.commit('mutateBookerSelectedAvailabilityId',availability_id);
                    // context.commit('mutateBookerPreselectedService',service_id);
                    context.commit('mutateBookerSelectedStage','booking');

                }).catch(err => {

                    alert(err.response.data.data.message || err);
                    Storage.remove('booking');
                    context.commit('mutateBookerVisibility',false);
                });
            } else {
                context.dispatch('showLoginForm');
            }
        },

        async checkIfAuthenticated(context) {
            const data = await axios.get(CAN_BOOK);
            return data.data.status;
        },

        initAvailabilityDrawer(context, business_id) {
            context.commit("mutateAvailabilityDrawerVisibility", true);
            context.commit("mutateAvailabilityDrawerBusinessId", business_id);
        },

        initServiceDrawer(context, slot) {
            context.commit('mutateServiceDrawerVisibility',true);
            context.commit('mutateAvailabilityDrawerVisibility',false);
            context.commit('mutateServiceDrawerBusinessId',slot.business_id || slot.business.id);
            context.commit('mutateServiceDrawerAvailability',slot);
        },

        initQuickLook(context, id, type = 'business') {
            context.commit('mutateQuickLookDrawerId',id);
            context.commit('mutateQuickLookDrawerVisibility',true);
        },

        showLoginForm(context, params) {
            context.commit("mutateOverlayModalContent", "loginForm");   
            if (params?.shouldPasswordReset) {
                context.dispatch('showResetPassword');
            } else {
                if (params) {
                    context.commit("mutateComponentParamsLoginForm", params);
                }

	    }
            context.commit("mutateOverlayVisibility", true);
        },

        showDemoRequestForm(context) {
            context.commit("mutateOverlayModalContent", "demoRequest");

            context.commit("mutateOverlayVisibility", true);
        },

        showResetPassword(context, params) {
            context.commit("mutateOverlayModalContent", "resetPassword");
            //context.commit('mutateComponentParamsResetPassword',params);
            context.commit("mutateOverlayVisibility", true);
        },

        showVimeoVideo(context, params) {
            context.commit("mutateOverlayModalContent", "vimeoVideo");
            context.commit("mutateComponentParamsVimeoVideo", params);
            context.commit(
                "mutateOverlayModalModifierClass",
                "c-overlay__modal--large"
            );
            context.commit("mutateOverlayVisibility", true);
        },

        showModalAlert(context, params) {
            context.commit("mutateOverlayModalContent", "genericModal");
            context.commit("mutateComponentParamsGenericModal", params);
            context.commit("mutateOverlayVisibility", true);
        },

        showAppAvailablePopup(context, params) {
            context.commit("mutateOverlayModalContent", "appAvailablePopup");
            context.commit(
                "mutateOverlayModalModifierClass",
                "c-overlay__modal--customer-app-notif"
            );
            context.commit("mutateOverlayVisibility", true);
        },
       
        showRightPlacePopup(context, params) {
            context.commit("mutateOverlayModalContent", "rightPlacePopup");
            context.commit(
                "mutateOverlayModalModifierClass",
                "c-overlay__modal--right-place-notif"
            );
            context.commit("mutateOverlayVisibility", true);
        },

        showMobileFilters(context, params) {
            context.commit("mutateFilterDrawerVisibility", true);
        },

        showAppointmentCancel(context, params) {
            context.commit("mutateOverlayModalContent", "appointmentCancel");
            context.commit("mutateOverlayVisibility", true);
            context.commit("mutateComponentParamsAppointmentCancel", params);
        },

        initBlogSingleGallery(context, slider) {
            context.commit("mutateOverlayModalContent", "blogSingleGallery");
            context.commit("mutateOverlayVisibility", true);
            context.commit(
                "mutateOverlayModalModifierClass",
                "c-overlay__modal--xl"
            );
            context.commit("mutateBlogSingleGallerySlider", slider);
        }
    }
});
