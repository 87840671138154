var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: function() {
            return _vm.mutateOverlayVisibility(false)
          },
          expression: "() => mutateOverlayVisibility(false)"
        }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center lg:px-6 md:pb-6 md:-mx-4" },
        [
          _c("div", { staticClass: "w-full md:w-7/12 px-4" }, [
            _c("img", {
              staticClass: "mb-4 inline-block mx-auto md:mx-0",
              attrs: {
                src: "/images/app-available-popup/admin-script-text.svg",
                alt: "Our app is now live!"
              }
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-copper-400 text-2xl md:text-3xl lg:text-4xl leading-tight font-poynter text-center md:text-left mb-6 w-full md:w-11/12 lg:w-full"
              },
              [
                _vm._v(
                  "Making it even easier to book your last minute appointments"
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex justify-center md:justify-start items-start mb-8 md:mb-0"
              },
              [
                _vm.returnOS() == "ios" || _vm.returnOS() == "unknown"
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://apps.apple.com/gb/app/eleventh-hour-beauty/id1533253533",
                          target: "_blank"
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "w-full",
                          attrs: {
                            src: "/images/app-available-popup/app-store.svg",
                            alt: "Download from the App Store"
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.returnOS() == "android" || _vm.returnOS() == "unknown"
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://play.google.com/store/apps/details?id=little.eleventh_hour.eleventh_hour_beauty",
                          target: "_blank"
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "w-full",
                          attrs: {
                            src: "/images/app-available-popup/google-store.svg",
                            alt: "Download from Google Play Store"
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ]
            )
          ]),
          _vm._v(" "),
          _vm._m(0)
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "w-full md:w-5/12 px-4 flex justify-center" },
      [
        _c("img", {
          staticClass: "w-full",
          attrs: {
            src: "/images/app-available-popup/users-image.svg",
            alt: "",
            "aria-hidden": "true"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }