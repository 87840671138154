var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      {
        staticClass: "c-filter-option c-form__auto-complete",
        class: _vm.search_filter.fields[_vm.selected]
          ? " c-filter-option--active"
          : null
      },
      [
        _c("vue-simple-suggest", {
          ref: "suggest",
          attrs: {
            placeholder: _vm.label,
            list: _vm.autocomplete.list,
            mode: "select",
            "display-attribute": "name",
            "value-attribute": "id",
            "filter-by-query": true,
            filter: _vm.customFilter,
            styles: _vm.autocomplete.styles,
            overrideFilterMethod: _vm.filterResults
          },
          on: { blur: _vm.pickTopSuggestion },
          model: {
            value: _vm.the_model,
            callback: function($$v) {
              _vm.the_model = $$v
            },
            expression: "the_model"
          }
        }),
        _vm._v(" "),
        _vm.search_filter.fields[_vm.selected] && !_vm.disableClear
          ? _c(
              "span",
              {
                staticClass: "c-filter-option__clear",
                attrs: {
                  role: "button",
                  "aria-label": "Remove this filter option"
                },
                on: { click: _vm.clearSelected }
              },
              [
                _c("i", { staticClass: "fal fa-times hidden md:inline-block" }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "inline-block md:hidden uppercase text-sm font-semibold"
                  },
                  [_vm._v("Edit")]
                )
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }