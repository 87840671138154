<template>
    <div v-show="match_found && !Object.keys(appointment).length">
        <a role="button" :class="className" @click="initAvailabilityDrawer(businessId); mutateQuickLookDrawerVisibility(false)">Book {{ service ? service.name : null }}</a>
    </div>
</template>

<script>
import { SERVICE, BUSINESS_AVAILABILITY } from '@js/endpoints';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
    data() {
        return {
            service:null,
            match_found:null,
        }
    },

    props: {
        businessId: {
            required: true,
            type: Number
        },

        className: {
            default: 'btn btn--copper cta'
        }
    },

    computed: {
        ...mapState({
            service_id: state => state.search_filter.fields.service_id,
            appointment: state => state.booker.appointment,
        })
    },

    methods: {
        ...mapMutations(['mutateQuickLookDrawerVisibility']),
        ...mapActions(['initAvailabilityDrawer']),

        getService() {
            axios.get(SERVICE(this.service_id)).then(res => {
                this.service = res.data.data;
                this.getAvailabilityCount();
            });
        },

        getAvailabilityCount() {
            axios.get(`${BUSINESS_AVAILABILITY}/${this.businessId}`, { params: {
                service_id: this.service_id
            }}).then(res => {
                this.match_found = res.data.data.length;
            });
        },
    },

    watch: {
        service_id() {
            this.getService();
        }
    },

    mounted() {
        this.service_id ? this.getService() : null;
    }
}
</script>

<style lang="scss" scoped>
    .cta {
        @apply shadow-lg fixed mx-auto z-50 opacity-0 text-center;
        left: 50%;
        bottom: 1rem;
        transform: translate(-50%, 10px);
        max-width: calc(100% - 4rem);
        animation: fadeUp .4s ease 1 forwards;

        @screen md {
            bottom: 50px;
        }
    }

    @keyframes fadeUp {
        100% { opacity: 1; transform: translate(-50%, 0); }
    }
</style>
