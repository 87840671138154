var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-6 px-8 md:px-0" }, [
    _vm.results_message
      ? _c("span", { staticClass: "mb-4 font-semibold" }, [
          _vm._v(_vm._s(_vm.results_message))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row row--tight" },
      [
        [
          _vm._l(_vm.results, function(availability, index) {
            return _c("search-result", {
              key: "availability-search-result-" + availability.availability_id,
              attrs: { availability: availability, isLoggedIn: _vm.isLoggedIn },
              on: {
                "toggle-favourite": function($event) {
                  return _vm.$emit("toggle-favourite", $event)
                }
              }
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.nearby, function(business, index) {
            return _c("search-result", {
              key: "availability-search-result-business-" + business.id,
              attrs: { business: business, isLoggedIn: _vm.isLoggedIn },
              on: {
                "toggle-favourite": function($event) {
                  return _vm.$emit("toggle-favourite", $event)
                }
              }
            })
          })
        ],
        _vm._v(" "),
        _vm.showSkeleton
          ? _vm._l(4, function(i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass:
                    "col flex flex-shrink-0 whitespace-normal w-full md:w-6/12 lg:w-4/12 xl:w-3/12 3xl:w-2/12 mt-6"
                },
                [_vm._m(0, true)]
              )
            })
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex flex-col w-full shadow-lg-allsides" },
      [
        _c(
          "div",
          {
            staticClass:
              "flex flex-wrap items-start flex-shrink-0 flex-grow p-4"
          },
          [
            _c("div", { staticClass: "flex flex-col w-8/12" }, [
              _c("span", { staticClass: "w-8/12 skeleton-text mb-2" }),
              _vm._v(" "),
              _c("span", { staticClass: "w-10/12 skeleton-text mb-2" })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-4/12 md:pl-2 md:text-right" }, [
              _c("span", {
                staticClass: "w-full skeleton-text skeleton-text--lg"
              })
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-grow-0 flex-shrink" }, [
          _c(
            "div",
            {
              staticClass:
                "relative skeleton-image skeleton-image--dark skeleton-image--no-shine",
              staticStyle: { "padding-top": "56.25%", "min-height": "225px" }
            },
            [
              _c("div", {
                staticClass:
                  "w-full absolute bottom-0 left-0 flex justify-between px-4 py-2 text-white c-image-overlay-gradient__inner"
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "skeleton-text",
          staticStyle: { height: "43px" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }