<template>
    <div class="col w-full mb-8 lg:mb-0 relative" :class="{'lg:w-8/12' : show_days || !business.is_mobile}">
        <!-- MAP -->
        <gmap-map class=" business-customer-map h-full w-full" :center="{ lat: parseFloat(business.lat), lng: parseFloat(business.lng) }" map-type-id="terrain" :zoom="16" :options="{ disableDefaultUi: true, zoomControl: true, mapTypeControl: false, streetViewControl: true, fullscreenControl: false, controlSize: 30 }" ref="map">
            <template v-if="is_mobile">
                <gmap-circle
                    v-for="user in users" :key="`user-radius-${user.id}`"
                    :center="{ lat: parseFloat(user.diary.lat), lng: parseFloat(user.diary.lng)}"
                    :radius="user.diary.radius_miles*1609.34"
                    :visible="true"
                    :options="{fillColor: user.color, strokeColor: user.stroke_color, strokeOpacity: '0.5', strokeWeight: 1}"
                    :fillOpacity:="0.5"
                    ref="map_circle"
                />
            </template>
            <template v-else>
                <gmap-marker :position="{ lat: parseFloat(business.lat), lng: parseFloat(business.lng)}" :icon="{ url: '/images/icons/pin-active--small.png'}"></gmap-marker>
            </template>
            
        </gmap-map>
        <!-- LEGEND -->
        <div class="providers-legend" v-if="is_mobile && users.length > 1">
            <div class="legend-bg">
                    <div class="provider-key" v-for="user in users" :key="`provider-key-item-${user.id}`">
                        <span class="key-color" :style="{'background-color': user.color, border: '1px solid'}"></span>
                        <label class="provider-key-name">{{user.full_name}}</label>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import { gmapApi } from 'vue2-google-maps';

export default {
    props: ['business', 'users', 'days'],
    computed: {
        is_mobile() {
            return this.business?.is_mobile;
        },

        show_days() {
            return this.days ? true : false;
        }
    },
    mounted() {
        // if this is a mobile business, then fit the map to the radii of its providers.
        if (this.business?.is_mobile) {
            this.$refs.map.$mapPromise.then(() => {
                this.$refs.map_circle[0].$circlePromise.then(() => {
                    let bounds = new window.google.maps.LatLngBounds();
                    this.$refs.map_circle.forEach(circle => {
                        bounds.union(circle.$circleObject.getBounds());
                    });
                    this.$refs.map.fitBounds(bounds, 0);
    
                });
            });
        }
    }
}
</script>
<style lang="scss" scoped>

</style>