<template>
    <div class="relative">
        <div class="c-filter-option c-form__auto-complete" :class="{'c-filter-option--active': (location_query == search_filter.fields.location.name)}">

            <input type="text" class="c-form__input w-full" v-model="location_query" @keyup.enter="geoSearch" @blur="researchIfRequired" ref="field">
            <span v-if="location_query.length" @click="clearSelected" class="c-filter-option__clear" role="button" aria-label="Remove this filter option" >
                <i class="fal fa-times hidden md:inline-block"></i>
                <span class="inline-block md:hidden uppercase text-sm font-semibold">Edit</span>
            </span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { LIVE_SEARCH_GEOLOCATION } from '@js/endpoints';

export default {


    data() {
        return {
            location_query:'',
        }
    },

    props: {

        label: {
            type: String
        },
    },

    computed: {
        ...mapState(['search_filter']),
    },

    methods: {
        geoSearch() {
            axios.get(LIVE_SEARCH_GEOLOCATION(this.location_query)).then(res => {

                if (res.data.status == "OK") {
                    this.search_filter.fields.location = {
                        lat: res.data.candidates[0].geometry.location.lat,
                        lng: res.data.candidates[0].geometry.location.lng,
                        name: res.data.candidates[0].formatted_address
                    };

                    this.location_query = res.data.candidates[0].formatted_address;
                }

                else {
                    alert(`Hmm.. we're struggling to find ${this.location_query}. Please try a different search term`);
                    this.location_query = this.search_filter.fields.location.name;
                }
            });
        },

        clearSelected() {
            this.location_query = '';
            this.$refs.field.focus();
        },

        researchIfRequired() {
            this.location_query && this.location_query !== this.search_filter.fields.location.name ? this.geoSearch() : this.location_query = this.search_filter.fields.location.name;
        }
    },

    mounted() {
        this.location_query = this.search_filter.fields.location.name;
    },
}
</script>

<style lang="scss" scoped>
    ::v-deep .c-form__auto-complete {
        @apply mb-0;
    }

    ::v-deep .c-filter-option--active input {
        @apply bg-grey-400 text-white;

        @screen md {
            @apply bg-teal-400 text-grey-1000;
        }
    }

    ::v-deep input {
        @apply py-1 px-2 bg-white;
    }

    ::v-deep .suggestions {
        min-width: 175px;
        max-width: 310px;
    }
</style>
