var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "c-image-uploader__image cursor-pointer",
        class: { "c-image-uploader__image--processing": _vm.processing },
        style: _vm.background
      },
      [
        _c("input", {
          ref: "upload_field",
          staticClass:
            "w-full opacity-0 top-0 left-0 right-0 bottom-0 absolute cursor-pointer",
          attrs: {
            type: "file",
            disabled: _vm.disabled,
            accept: "image/*",
            name: _vm.name
          },
          on: {
            change: function($event) {
              _vm.generatePreview($event)
              _vm.preventNavigateAway($event)
            }
          }
        }),
        _vm._v(" "),
        !_vm.disabled
          ? _c(
              "div",
              {
                staticClass:
                  "absolute text-center border-gray-500 text-white rounded-full bg-grey-1000 text-white",
                class: { "pointer-events-none": !_vm.preview },
                staticStyle: {
                  bottom: "10px",
                  left: "50%",
                  transform: "translatex(-50%)",
                  width: "30px",
                  height: "30px"
                }
              },
              [
                !_vm.preview
                  ? _c("i", {
                      staticClass: "fas fa-pencil-alt text-sm relative",
                      staticStyle: { top: "2px" }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.preview
                  ? _c("i", {
                      staticClass:
                        "fal fa-trash-alt text-sm relative cursor-pointer",
                      staticStyle: { top: "2px" },
                      on: { click: _vm.clearField }
                    })
                  : _vm._e()
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }