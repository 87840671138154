<template>
    <div class="relative" v-on-clickaway="() => { interacting = false }">
        <div class="c-filter-option" :class="{ 'c-filter-option--active' : option_selected }" @click="interacting = !interacting">
            <vue-simple-suggest :placeholder="label" :list="autocomplete.serviceList" mode="select" display-attribute="name" value-attribute="id" :filter-by-query="true" v-model="q" :styles="autocomplete.styles" ref="suggestions" 
            :overrideFilterMethod="filterResults"></vue-simple-suggest>
            <span v-if="option_selected" @click="clearSelected" class="c-filter-option__clear" role="button" aria-label="Remove this filter option"><i class="fal fa-times"></i></span>
        </div>
    </div>
</template>

<script>
// import VueSimpleSuggest from 'vue-simple-suggest';
import { LIVE_SEARCH_SERVICES } from '@js/endpoints';
import { mapState } from 'vuex';
import {filter} from 'fuzzaldrin';

export default {
    components: {
        // VueSimpleSuggest
    },

    data() {
        return {
            interacting: false,
            requested_service: null,
            q: null,

            autocomplete: {
                styles: {
                    defaultInput: "c-form__input w-full"
                },
                serviceList: []
            }
        }
    },

    props: {
        name: {
            type: String,
            required: true
        },

        label: {
            type: String,
            required: true
        },

        preselectedService: {
            type: Number,
            required: false
        }
    },

    mounted() {
        this.fetchServiceList();
    },

    watch: {
        q() {
            this.search_filter.fields[this.name] = this.q.id;
        },

        'autocomplete.serviceList'() {
           this.prepopulate();
        }
    },

    computed: {
        ...mapState(['search_filter']),

        option_selected() {
            return this.q;
        },

        output_requested_service() {
            return (this.q ? this.q.name : null);
        }
    },

    methods: {
        fetchServiceList() {
            axios.get(`${LIVE_SEARCH_SERVICES}`).then(res => {
                this.autocomplete.serviceList = res.data.data;
            });
        },

        clearSelected() {
            this.q = '';
            this.$refs.suggestions.input.value = '';
        },

        prepopulate() {
            this.q = this.autocomplete.serviceList.find(service => service.id == this.preselectedService) || null;
        },
        filterResults(results, query) {
            return filter(results, query, {key: 'name'})
        },
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep input {
        @apply py-1 px-2 mb-0;
        background-color: transparent;
    }

    ::v-deep .suggestions {
        @apply absolute left-0 w-full pt-2 pb-4 mb-0 text-base text-grey-1000 bg-white shadow-lg-allsides z-50;
        top: calc(100% + 0.5rem);
        z-index: 1;
        max-height: 200px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        .suggest-item {
            @apply py-1 px-4;

            &:hover,
            &.hover {
                @apply bg-grey-200;
            }
        }
    }
</style>
