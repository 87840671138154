<template>
    <div class="c-popup-drawer">
        <div class="c-popup-drawer__inner">
            <div>
                <div class="md:flex md:flex-grow-0 md:flex-shrink md:items-start md:items-center justify-between flex-grow mb-10">
                    <div class="flex items-start flex-shrink flex-grow-0 mb-4 md:mb-0 md:mr-2">
                        <!-- <div v-if="data.rating" class="c-star-rating mr-3"><span class="c-star-rating__number">{{ data.rating }}</span></div> -->
                        <div>
                            <h2 class="mb-0 text-copper-400">{{ data.name }}</h2>
                            <p class="mb-0 font-semibold">{{ data.address }}</p>
                        </div>
                    </div>
                    <div class="md:flex md:flex-grow md:justify-end flex-shrink-0">
                        <div class="flex-shrink-0 md:mr-2">
                            <a :href="data.link" class="btn btn--grey w-full md:w-auto text-center">View</a>
                        </div>
                        <div class="mt-4 md:mt-0">
                            <!-- <a :href="data.link" class="btn btn--teal">Book</a> -->
                            <book-suggestion :business-id="quick_look_drawer.id" class-name="btn btn--teal w-full md:w-auto text-center"></book-suggestion>
                        </div>
                    </div>
                </div>

                <div class="md:flex md:flex-wrap md:items-center overflow-hidden">
                    <div class="-mx-2 mb-10 lg:w-7/12 flex flex-row justify-start mb-6 lg:mb-0 md:mr-12 overflow-x-auto">
                        <div v-for="(image, index) in data.images" :key="index"  class="px-2 w-11/12 md:w-4/12 flex-shrink-0">
                            <img :src="image.url" class="md:w-full">
                        </div>
                    </div>
                    <div class="w-full lg:w-5/12">
                        <p>{{ data.content }}</p>
                    </div>
                </div>
            </div>
        </div>
        <button aria-label="Close" class="c-drawer-close" @click="mutateQuickLookDrawerVisibility(false)">
            <span class="c-drawer-close__icon" style="top:-2px">&times;</span>
            <span class="c-drawer-close__tooltip">Close?</span>
        </button>
    </div>
</template>

<script>
import { QUICK_LOOK } from '@js/endpoints';
import { mapState, mapMutations } from 'vuex';
export default {

    data() {
        return {
            data: {
                name: null,
                address: null,
                rating: null,
                link: null,
            }
        }
    },

    mounted() {
        this.fetchData();
    },

    watch: {
        'quick_look_drawer.id'() {
            this.fetchData();
        }
    },

    computed: {
        ...mapState(['quick_look_drawer'])
    },

    methods: {
        fetchData() {
            axios.get(`${QUICK_LOOK}/${this.quick_look_drawer.type}/${this.quick_look_drawer.id}`).then(res => {
                this.data = res.data.data;
            });
        },

        ...mapMutations(['mutateQuickLookDrawerVisibility'])
    },
}
</script>

<style lang="scss" scoped>

</style>
