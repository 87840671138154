<template>
    <div class="mb-6 px-8 md:px-0">
        <span v-if="results_message" class="mb-4 font-semibold">{{results_message}}</span>
            <!-- <div class="horizontal-scroll-x-gradient"> -->
                <!-- <div class="row row--tight horizontal-scroll-track py-2"> -->
                <div class="row row--tight">
                    <!-- Remove the mt-6 from the below element when this component goes back to a horinzontally scrolling area -->
                    <template>
                        <search-result v-for="(availability, index) in results"
                            :key="`availability-search-result-${availability.availability_id}`"
                            :availability="availability"
                            :isLoggedIn="isLoggedIn"
                            v-on:toggle-favourite="$emit('toggle-favourite', $event)"

                        />

                        <!-- SHOW UNAVAILABLE BUSINESSES -->
                        <search-result v-for="(business, index) in nearby"
                            :key="`availability-search-result-business-${business.id}`"
                            :business="business"
                            :isLoggedIn="isLoggedIn"
                            v-on:toggle-favourite="$emit('toggle-favourite', $event)"

                        />

                        <!-- No results -->
                        <!-- <div class="section-pt--md text-center w-full" v-if="Array.isArray(results) && !results.length && !showSkeleton">
                            <img src="/images/headshots/no-results.png" aria-hidden="true" class="no-results-img">
                            <h2 class="mb-2">Oh no!</h2>
                            <p class="mb-0 text-black text-xl">
                                 It looks like our businesses are busy at these times<br>
                                Try making the search less specific, if you can
                            </p>
                        </div> -->
                        <!-- / No results -->
                    </template>

                    <template v-if="showSkeleton">
                        <div class="col flex flex-shrink-0 whitespace-normal w-full md:w-6/12 lg:w-4/12 xl:w-3/12 3xl:w-2/12 mt-6" v-for="i in 4" :key="i">
                            <div class="flex flex-col w-full shadow-lg-allsides">
                                <div class="flex flex-wrap items-start flex-shrink-0 flex-grow p-4">
                                    <div class="flex flex-col w-8/12">
                                        <span class="w-8/12 skeleton-text mb-2"></span>
                                        <span class="w-10/12 skeleton-text mb-2"></span>
                                    </div>
                                    <div class="w-4/12 md:pl-2 md:text-right">
                                        <span class="w-full skeleton-text skeleton-text--lg"></span>
                                    </div>
                                </div>
                                 <div class="flex-grow-0 flex-shrink">
                                    <div class="relative skeleton-image skeleton-image--dark skeleton-image--no-shine" style="padding-top:56.25%; min-height:225px;">
                                        <div class="w-full absolute bottom-0 left-0 flex justify-between px-4 py-2 text-white c-image-overlay-gradient__inner"></div>
                                    </div>
                                 </div>
                                <div class="skeleton-text" style="height:43px;"></div>
                            </div>
                        </div>
                    </template>
                </div>
            <!-- </div> -->

        </div>

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {

    props: {
        results: {
            type: Array,
            default: []

        },

        showSkeleton: {
            type: Boolean,
            default: false
        },

        isLoggedIn: {
            type: Boolean,
            default: false
        },

        // If the API returned more 'relaxed' parameters than what were entered, in order to improve results:
        results_message: {
            type: String,
        },
        nearby: { },
    },

    methods: {
        ...mapActions(['initBooking','initQuickLook', 'initAvailabilityDrawer']),

    },

}
</script>

<style lang="scss" scoped>

</style>
