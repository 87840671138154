<template>
    <div v-on-clickaway="() => mutateOverlayVisibility(false)" style="padding-left:0; padding-right:0; padding-bottom:0;">

        <h1 class="text-center">Login</h1>
        <form v-on:submit.prevent="doLogin" method="post" class="mb-4 pl-4 pr-4">
            <input type="email" name="email" class="c-form__input w-full" v-model="email" placeholder="Email Address">
            <span class="c-form__inline-error" v-if="errors.email">{{ errors.email[0] }}</span>

            <input type="password" name="password" class="c-form__input w-full" v-model="password" placeholder="Password">
            <span class="c-form__inline-error" v-if="errors.password">{{ errors.password[0] }}</span>

            <button class="btn btn--teal text-grey-1000 w-full">{{ login_label }}</button>
        </form>

        <div class="text-center mb-10 pl-4 pr-4">
            <a @click="showResetPassword" role="button" class="cursor-pointer inline-block text-base mb-6">Forgotten Your Password?</a>

        </div>
        <div class="text-center bg-grey-200 p-4 rounded-b">
            <a :href="params.links.signup" class="block uppercase text-grey-1000 font-semibold text-base">Not a member? Sign up here</a>
        </div>
    </div>


</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            email: null,
            password: null,
            login_label: "Login",
            errors: {
                email: null,
                password: null
            },
        }
    },

    computed: {
        ...mapState({
            overlay: state =>  state.overlay,
            params: state => state.component_params.loginForm
        })
    },

    methods: {
        ...mapMutations(['mutateOverlayVisibility']),
        ...mapActions(['showResetPassword']),

        doLogin() {
            this.login_label = "Logging you in...";
            axios.post(this.params.endpoint, {
                email: this.email,
                password: this.password
            }).then(res => {
               window.location = res.data.authenticated.redirect || window.location;
            }).catch(err => {
                if (err.response.data.errors) {
                    this.errors = err.response.data.errors;
                    this.password = null;
                    this.login_label = "Login";
                }

                else {
                    alert(err);
                }
            });
        }
    },
}
</script>

<style lang="scss" scoped>
    .c-input-text {
        @apply border p-4 rounded-sm w-full mb-4;
    }
</style>
