var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: function() {
            return (_vm.toolTipOpen = false)
          },
          expression: "() => toolTipOpen = false"
        }
      ],
      staticClass: "c-tooltip"
    },
    [
      _c(
        "span",
        {
          staticClass: "c-tooltip__trigger",
          on: {
            click: function($event) {
              _vm.toolTipOpen = !_vm.toolTipOpen
            }
          }
        },
        [
          _vm.triggerText
            ? _c(
                "span",
                { staticClass: "text-grey-1000 flex-grow-0 flex-shrink" },
                [_vm._v(_vm._s(_vm.triggerText) + " ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "relative" }, [
            _c("i", {
              staticClass: "fas fa-info",
              staticStyle: { "font-size": "8px" }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "c-tooltip__content",
                class: { "c-tooltip__content--visible": _vm.toolTipOpen }
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.content))]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "c-tooltip__close",
                    attrs: { role: "button", "aria-label": "Close tooltip" },
                    on: {
                      click: function($event) {
                        _vm.toolTipOpen = false
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "far fa-times",
                      attrs: { "data-v-45bb487d": "" }
                    })
                  ]
                )
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }