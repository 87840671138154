<template>
    <div v-show="!search_filter.fields.mobile">
        <div class="c-form__radio-as-toggle">
            <input type="radio" name="view-type" id="list" value="list" v-model="search_filter.view_type">
            <label for="list">List</label>

            <input type="radio" name="view-type" id="map" value="map" v-model="search_filter.view_type">
            <label for="map">Map</label>
        </div>

        <div class="md:hidden">
            <input type="checkbox" class="hidden" id="mobile-view-toggle" v-model="search_filter.view_type" true-value="list" false-value="map">
            <label for="mobile-view-toggle" class="text-grey-1000 uppercase font-semibold text-base cursor-pointer">{{ search_filter.view_type == "map" ? "List" : "Map"}} View</label>
        </div>
    </div>


</template>

<script>
import { mapState } from 'vuex';

export default {

    computed: {
        ...mapState(['search_filter'])
    },
}
</script>

<style lang="scss" scoped>
    .c-form__radio-as-toggle {
        @apply mb-0;
        display:none;

        //Regular tailwind 'hidden md:block' not working
        @screen md {
            display: flex;
        }
    }



</style>
