<template>
    <div v-on-clickaway="() => mutateOverlayVisibility(false)" style="padding-left:0; padding-right:0; padding-bottom:0;">
        <h1 class="text-center">{{ sent ? "Thank you" : "Book A Demo" }}</h1>

        <form v-on:submit.prevent="sendForm" method="post" class="mb-4 pl-4 pr-4" v-if="!sent">
            <p class="text-center">Enter your details below, and one of our team will get back to you to arrange a demo.</p>
            <input type="text" name="name" required class="c-form__input w-full" v-model="form.name" placeholder="Your Name*">
            <span class="c-form__inline-error" v-if="errors.name">{{ errors.name[0] }}</span>

            <input type="text" name="business" required class="c-form__input w-full" v-model="form.business" placeholder="Business Name*">
            <span class="c-form__inline-error" v-if="errors.business">{{ errors.business[0] }}</span>

            <input type="email" name="email" required class="c-form__input w-full" v-model="form.email" placeholder="Email Address*">
            <span class="c-form__inline-error" v-if="errors.email">{{ errors.email[0] }}</span>

            <input type="tel" name="tel" class="c-form__input w-full" v-model="form.tel" placeholder="Tel">
            <span class="c-form__inline-error" v-if="errors.tel">{{ errors.tel[0] }}</span>

            <input type="text" name="_pass_word" v-model="form.password" autocomplete="nope!" class="opacity-0 w-0 h-0 absolute overflow-hidden block">

            <button class="btn btn--teal text-grey-1000 w-full" :class="{'opacity-50 pointer-events-none': !fields_valid}" :disabled="!fields_valid">Send</button>
        </form>

        <p v-else class="mb-4 pl-4 pr-4 text-center">A member of our team will be in touch to arrange a suitable date and time.</p>
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import { BOOK_DEMO } from '@js/endpoints';

export default {
    data() {
        return {
            form: {
                email: '',
                business: '',
                name: '',
                tel: ''
            },
            errors: {
                email: null,
                name: null,
                business: null,
                tel: null
            },
            sent: false
        }
    },

    computed: {
        ...mapState({
            overlay: state =>  state.overlay,
        }),

        fields_valid() {
            return  this.form.email.length &&
                    this.form.name.length &&
                    this.form.business.length
                ?   true
                :   false;
        },

    },

    methods: {
        ...mapMutations(['mutateOverlayVisibility']),
        sendForm() {
            axios.post(BOOK_DEMO, this.form).then(res => {
                this.sent = true;
                gtag('event', 'conversion', {
                  'send_to': 'AW-624578451/rx-BCKji0NMBEJOf6akC',
                  'event_callback': callback
                });
            }).catch(err => {
                if (err.response) {
                    this.errors = err.response.data.errors;
                } else {
                    console.log(err);
                }
            });
        }
    },
}
</script>

<style lang="scss" scoped>
    .c-input-text {
        @apply border p-4 rounded-sm w-full mb-4;
    }
</style>
