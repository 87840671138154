<template>
  <div class="c-popup-drawer">
    <div class="c-popup-drawer__inner pad-bottom-60">
      <div>
        <button
          aria-label="Close"
          class="c-drawer-close"
          @click="close"
        >
          <span class="c-drawer-close__icon" style="top:-2px">&times;</span>
          <span class="c-drawer-close__tooltip">Cancel?</span>
        </button>

        <h2 class="text-copper-400">
          Available Services - {{ availability[0] ? availability[0].business_name : null }}
        </h2>

        <p v-if="!availability.length">Loading services...</p>

        <table v-if="services" class="c-stylised-table w-full text-grey-1000">
          <tr v-for="(service, index) in services" :key="index">
            <td>
              <strong>{{ service.name }}</strong>
              <span class="text-102">
                {{formatDuration(service && service.pivot && service.pivot.time_required || "00:00:00")}}
              </span>              <br />
              <!-- <span class="text-copper-400 italic">
                with
                <a
                  :href="service.service_provider.link"
                  class="underline hover:no-underline"
                >{{ service.service_provider.name }}</a>
              </span> -->
            </td>
            <td class="text-grey-1000 font-poynter font-bold text-lg text-right">
              <span class="flex flex-row">
                <span class="italic text-102 mr-1" >From </span> <span>{{toCurrency(service && service.pivot && service.pivot.cost / 100 || 0)}}</span>
              </span>
            </td>
            <td>
              <a class="btn btn--teal" @click="chooseService(service)">Book</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { BUSINESS_AVAILABILITY } from "@js/endpoints";
import moment from "moment";

export default {
  data() {
    return {
      availability: [],
    };
  },

  props: {},

  computed: {
    ...mapState(["service_drawer", "search_filter"]),
    ...mapGetters(['adjusted_search_fields']),

    services() {
      
      return this.service_drawer.availability.services || [];
    },
  },

  watch: {
    "service_drawer.business_id"() {
      this.getBusinessAvailability();
    }
  },

  mounted() {
    this.getBusinessAvailability();
    this.mutateIsBooking(true);
  },

  methods: {
    ...mapMutations([
      "mutateServiceDrawerVisibility",
      "mutateSearchFilterFieldsServiceId",
      "mutateIsBooking",
    ]),

    ...mapActions([
      "initBooking",
      "initAvailabilityDrawer",
    ]),
    
    close() {
        this.mutateServiceDrawerVisibility(false);
        this.mutateIsBooking(false);
    },

    chooseService(service) {
      this.mutateSearchFilterFieldsServiceId(service.id);
      this.mutateServiceDrawerVisibility(false);
      // this.initAvailabilityDrawer(this.service_drawer.business_id)
      this.initBooking({availability_id: this.service_drawer.availability.availability_id || this.service_drawer.availability.id});
    },

    getBusinessAvailability() {
      axios
        .get(
          `${BUSINESS_AVAILABILITY}/${this.service_drawer.business_id }`,
          {
            params: this.adjusted_search_fields
          }
        )
        .then(res => {
          this.availability = res.data.data;
        });
    },

    formatDate(start, end) {
      let times =
        moment.utc(start).format("h:mmA") +
        " - " +
        moment.utc(end).format("h:mmA");

      return moment.utc(start).isSame(moment.utc(), "day")
        ? `Today ${times}`
        : moment.utc(start).isSame(moment.utc().add(1, "days"), "day")
        ? `Tomorrow ${times}`
        : moment.utc(start).format("dddd Do MMMM") + ` ${times}`;
    },
  },

  watch: {
    "search_filter.fields": {
      deep: true,
      handler() {
        this.getBusinessAvailability();
      }
    },

    availability(new_val, old_val) {
      if (!new_val.length && old_val.length) {
        this.mutateAvailabilityDrawerVisibility(false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  td:last-of-type {
    width: 100px;
  }

  td:nth-last-child(2) {
    width: 100px;
  }
}

.text-102 {
    color: rgb(102, 102, 102);
}
.pad-bottom-60 {
	padding-bottom: 60px;
}
</style>
