var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: function() {
            _vm.interacting = false
          },
          expression: "() => { interacting = false }"
        }
      ],
      staticClass: "relative"
    },
    [
      _c(
        "div",
        {
          staticClass: "c-filter-option",
          class: { "c-filter-option--active": _vm.option_selected },
          on: {
            click: function($event) {
              _vm.interacting = !_vm.interacting
            }
          }
        },
        [
          _c("vue-simple-suggest", {
            ref: "suggestions",
            attrs: {
              placeholder: _vm.label,
              list: _vm.autocomplete.serviceList,
              mode: "select",
              "display-attribute": "name",
              "value-attribute": "id",
              "filter-by-query": true,
              styles: _vm.autocomplete.styles,
              overrideFilterMethod: _vm.filterResults
            },
            model: {
              value: _vm.q,
              callback: function($$v) {
                _vm.q = $$v
              },
              expression: "q"
            }
          }),
          _vm._v(" "),
          _vm.option_selected
            ? _c(
                "span",
                {
                  staticClass: "c-filter-option__clear",
                  attrs: {
                    role: "button",
                    "aria-label": "Remove this filter option"
                  },
                  on: { click: _vm.clearSelected }
                },
                [_c("i", { staticClass: "fal fa-times" })]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }