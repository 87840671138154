var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: function() {
            return _vm.mutateOverlayVisibility(false)
          },
          expression: "() => mutateOverlayVisibility(false)"
        }
      ]
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-wrap items-center items-stretch p-0 right-place"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-full text-center p-16 text-white flex flex-col items-center justify-evenly h-400 bg-copper-400"
            },
            [
              _c("span", { staticClass: "font-semibold" }, [
                _vm._v("BUSINESS")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "-mx-1 font-poynter text-2xl" }, [
                _vm._v("I am a business and I want to know more")
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn--dark-grey px-8 text-center uppercase",
                  on: { click: _vm.goToBusiness }
                },
                [_vm._v("I'm a business")]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }