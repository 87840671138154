var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-popup-drawer" }, [
    _c("div", { staticClass: "c-popup-drawer__inner pad-bottom-60" }, [
      _c("div", [
        _c(
          "button",
          {
            staticClass: "c-drawer-close",
            attrs: { "aria-label": "Close" },
            on: { click: _vm.close }
          },
          [
            _c(
              "span",
              {
                staticClass: "c-drawer-close__icon",
                staticStyle: { top: "-2px" }
              },
              [_vm._v("×")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "c-drawer-close__tooltip" }, [
              _vm._v("Cancel?")
            ])
          ]
        ),
        _vm._v(" "),
        _c("h2", { staticClass: "text-copper-400" }, [
          _vm._v(
            "\n        Available Services - " +
              _vm._s(
                _vm.availability[0] ? _vm.availability[0].business_name : null
              ) +
              "\n      "
          )
        ]),
        _vm._v(" "),
        !_vm.availability.length
          ? _c("p", [_vm._v("Loading services...")])
          : _vm._e(),
        _vm._v(" "),
        _vm.services
          ? _c(
              "table",
              { staticClass: "c-stylised-table w-full text-grey-1000" },
              _vm._l(_vm.services, function(service, index) {
                return _c("tr", { key: index }, [
                  _c("td", [
                    _c("strong", [_vm._v(_vm._s(service.name))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-102" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.formatDuration(
                              (service &&
                                service.pivot &&
                                service.pivot.time_required) ||
                                "00:00:00"
                            )
                          ) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("br")
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass:
                        "text-grey-1000 font-poynter font-bold text-lg text-right"
                    },
                    [
                      _c("span", { staticClass: "flex flex-row" }, [
                        _c("span", { staticClass: "italic text-102 mr-1" }, [
                          _vm._v("From ")
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.toCurrency(
                                (service &&
                                  service.pivot &&
                                  service.pivot.cost / 100) ||
                                  0
                              )
                            )
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn--teal",
                        on: {
                          click: function($event) {
                            return _vm.chooseService(service)
                          }
                        }
                      },
                      [_vm._v("Book")]
                    )
                  ])
                ])
              }),
              0
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }