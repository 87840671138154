<template>
    <div class="col flex flex-shrink-0 whitespace-normal w-full md:w-6/12 lg:w-4/12 xl:w-3/12 3xl:w-3/12 mt-6 c-salon-preview-card" 
        
    >
        <div v-if="availability" class="flex flex-col w-full shadow-lg-allsides">
            <span v-if="availability.more_times" class="uppercase text-black text-center text-sm font-semibold p-1 bg-teal-400">
                More times available
            </span>

            <div class="flex flex-wrap items-center flex-shrink-0 flex-grow-0 p-4"
                :class="{'availability-spacer': !availability.more_times}"
             >
                <div class="flex flex-col w-8/12">
                    <span class="label">{{ availability.day_word }}</span>
                    <span class="text-copper-400 font-poynter font-bold text-2xl -mt-1">
                        {{ formatTime(availability.availability_start) }} - {{ formatTime(availability.availability_end) }}
                    </span>
                </div>

                <div class="w-4/12 md:pl-2 flex flex-col text-right">
                    <span class="block md:inline-block font-semibold text-sm">From</span>
                    <span class="text-grey-1000 font-poynter font-bold text-2xl -mt-1 ">
                        {{ availability.cost_from }}
                    </span>
                </div>
            </div>

            <div class="flex-grow-0 flex-shrink">
                <div class="flex relative bg-center bg-no-repeat bg-cover c-image-overlay-gradient"
                    :style="business_image_style"
                >
                    <div class="w-full absolute bottom-0 left-0 flex items-center justify-between px-4 py-2 text-white c-image-overlay-gradient__inner">
                        <div class="w-9/12 pr:4">
                            <p class="font-semibold text-base mb-0">
                                <a @click="initQuickLook(availability.business.id)" class="cursor-pointer md:underline hover:no-underline text-white">
                                    {{ availability.business.name }}
                                </a>
                            </p>
                            <!-- <template v-if="availability.business.rating">
                                <i class="fas fa-star text-sm relative mr-1" style="top:-1px;"></i>{{ availability.business.rating }}
                            </template> -->
                            <span class="hidden md:inline-block font-normal text-base text-white ">
                                {{ availability.business.address_line_3 }} ({{ getAvailDistance(availability) }} miles)
                            </span>
                        </div>
                    </div>

                    <button v-if="isLoggedIn" class="favourite-btn"
                        :class="availability.favourite ? 'favourite-btn--active' : null"
                        @click="$emit('toggle-favourite', availability.business.id)"
                    >
                        <i class="fa-heart" :class="availability.favourite ? 'fas' : 'fal'"></i>
                    </button>
                </div>
            </div>

            <!-- Services List -->
            <div v-if="services_by_provider.length" class="flex flex-col flex-grow px-4 pb-1 text-base" :class="{'services-gradient': services_by_provider.length > 3}">
                <span class="italic text-sm pt-2 pb-1">{{services_by_provider.length}} {{services_by_provider.length > 1 ? 'Services' : 'Service'}} Available</span>
                <div class="flex flex-row py-1 border-bottom" v-for="service in services_by_provider.slice(0, 3)" :key="`availability-service-${availability.id}-${service.id}`">
                    <span class="service-name text-copper-400 font-bold mr-2">
                        {{service.name}}
                        <span v-if="service.duration" class="service-duration mr-1 font-normal text-102">
                            {{formatDuration(service.duration)}}
                        </span>
                    </span>

                    <span v-if="service.cost" class="service-cost font-bold text-black flex-1 text-right">
                        <span class="font-normal text-102 ">From</span> £{{service.cost / 100}}
                    </span>
                    <span v-else class="service-cost font-bold text-black flex-1 text-right">
                        FREE
                    </span>

                </div>
            </div>

            <div class="flex uppercase font-bold text-center">
                <div class="flex items-center justify-center flex-grow flex-shrink md:hidden bg-grey-400">
                    <span @click="initQuickLook(availability.business.id)" role="button" class="text-white">
                        Info
                    </span>
                </div>
                <div class="flex-grow flex-shrink bg-teal-400 md:bg-grey-400 py-2 cursor-pointer md:hover:bg-green-500 text-grey-1000 md:text-white"
                    @click="bookClicked"
                >
                {{
                    ((availability.more_times || (services_by_provider.length > 1 && !search_filter.fields.service_id) )? `See ${( !availability.more_times && services_by_provider.length > 1  && !search_filter.fields.service_id) ?  'Services' : 'Times'} & Book` : "Book") 
                }}

                </div>
            </div>
        </div>

        <!-- NOT AN AVAILABILITY -->
        <div v-else-if="business" class="flex flex-col w-full shadow-lg-allsides">
            <span v-if="business.services.length > 0" class="uppercase text-black text-center text-sm font-semibold p-1 bg-grey-400 text-white">
                Recently Available
            </span>
            <span v-else class="uppercase text-black text-center text-sm font-semibold p-1 bg-grey-400 text-white">
                Nearby Business
            </span>
            <div class="flex flex-wrap items-center flex-shrink-0 flex-grow-0 p-4"
                :class="{'availability-spacer': false}"
             >
                <div class="flex flex-col w-8/12">
                    <span class="label">
                        <!-- {{ availability.day_word }} -->
                    </span>
                    <span class="text-copper-400 font-poynter font-bold text-2xl -mt-1">
                        <!-- {{ formatTime(availability.availability_start) }} - {{ formatTime(availability.availability_end) }} -->
                    </span>
                </div>

                <div class="w-4/12 md:pl-2 flex flex-col text-right">
                    <span class="block md:inline-block font-semibold text-sm">From</span>
                    <span class="text-grey-1000 font-poynter font-bold text-2xl -mt-1 ">
                        {{toCurrency(cheapest)}}
                    </span>
                </div>
            </div>

            <div class="flex-grow-0 flex-shrink">
                <div class="flex relative bg-center bg-no-repeat bg-cover c-image-overlay-gradient"
                    :style="business_image_style"
                >
                    <div class="w-full absolute bottom-0 left-0 flex items-center justify-between px-4 py-2 text-white c-image-overlay-gradient__inner">
                        <div class="w-9/12 pr:4">
                            <p class="font-semibold text-base mb-0">
                                <a @click="initQuickLook(business.id)" class="cursor-pointer md:underline hover:no-underline text-white">
                                    {{ business.name }}
                                </a>
                            </p>
                            <!-- <template v-if="availability.business.rating">
                                <i class="fas fa-star text-sm relative mr-1" style="top:-1px;"></i>{{ availability.business.rating }}
                            </template> -->
                            <span class="hidden md:inline-block font-normal text-base text-white ">
                                {{ business.address_line_3 }} ({{ business.distance }} miles)
                            </span>
                        </div>
                    </div>

                    <button v-if="isLoggedIn" class="favourite-btn"
                        :class="business.favourite ? 'favourite-btn--active' : null"
                        @click="$emit('toggle-favourite', business.id)"
                    >
                        <i class="fa-heart" :class="business.favourite ? 'fas' : 'fal'"></i>
                    </button>
                </div>
            </div>

            <!-- Services List -->
            <div v-if="business.services.length" class="flex flex-col flex-grow px-4 pb-1 text-base" :class="{'services-gradient': business.services.length > 3}">
                <span class="italic text-sm pt-2 pb-1">
                    <!-- {{business.services.length}} {{business.services.length > 1 ? 'Services' : 'Service'}}  -->
                    Services Offered
                </span>
                <div class="flex flex-row py-1 border-bottom" v-for="service in business.services.slice(0, 3)" :key="`availability-business-service-${business.id}-${service.id}`">
                    <span class="service-name text-copper-400 font-bold mr-2">
                        {{service.name}}
                        <span v-if="service.time_required" class="service-duration mr-1 font-normal text-102">
                            {{formatDuration(service.time_required)}}
                        </span>
                    </span>

                    <span v-if="service.cheapest" class="service-cost font-bold text-black flex-1 text-right">
                        <span class="font-normal text-102 ">From</span> £{{service.cost}}
                    </span>
                    <span v-else class="service-cost font-bold text-black flex-1 text-right">
                        {{toCurrency(service.cost)}}
                    </span>

                </div>
            </div>
            <div v-else class="flex-1">&nbsp;
            </div>

            <div class="flex uppercase font-bold text-center">
                <div class="flex-grow flex-shrink bg-teal-400 md:bg-grey-400 py-2 cursor-pointer md:hover:bg-green-500 text-grey-1000 md:text-white"
                    @click="initQuickLook(business.id)"
                >
                    INFO
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { mapState, mapActions, mapMutations } from 'vuex';

export default {

    props: {
        availability: {
            type: Object,
            default: null
        },

        isLoggedIn: {
            type: Boolean,
            default: false
        },

        business: {},
    },

    computed: {
        ...mapState([
            'search_filter'
        ]),

        cheapest() {
            return this.business?.services.sort((s1, s2) => {
                if (s1.cost > s2.cost) return 1;
                if (s1.cost < s2.cost) return -1;
                return 0;
            })[0]?.cost;
        },

        business_image_style() {
            return {
                'background-image': `url('${this.availability?.business.image || this.business?.image || 'images/salon_images/business-coming-soon.jpg'}')`,
                'padding-top' :'75%',
                'min-height': '225px'
            }
        },
        services_by_provider() {
            return this.availability.services.map(service => ({...service, duration: service.pivot.time_required, cost: service.pivot.cost}));
            return Object.entries(this.availability.services).map(([service_name, providers]) => {
                let provider_array = Object.values(providers);
                return {
                    name: service_name,
                    cheapest: provider_array.map(provider => {
                        return provider.reduce((cost, curProvider) => {
                            return cost < curProvider.cost ? cost : curProvider.cost;
                        }, provider[0].cost);
                    }).reduce((cost, prov ) => (cost < prov? cost : prov), 1000000000000 ),
                    duration: provider_array[0]?.[0].pivot? (provider_array[0][0].pivot?.time_required) : provider_array[0][0],
		    id: provider_array[0]?.[0].id
                }
            }).sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
        },
        services() {
            return this.services_by_provider.map()
        }
    },

    mounted(){

    },

    methods: {
        ...mapActions(['initBooking','initQuickLook', 'initAvailabilityDrawer', 'initServiceDrawer', 'showLoginForm']),
        ...mapMutations(['mutateSearchFilterFieldsServiceId']),
        getAvailDistance(avail) {
            return (avail?.travel?.distance?.text  || 0) * 0.62137119
            || avail.business.distance
        },
        bookClicked() {
            if (!this.isLoggedIn) {
                this.showLoginForm()
            } else {
                let hasMultipleServices = this.availability.services.length > 1 && !this.search_filter.fields.service_id;
                let hasMultipleTimes = this.availability.more_times;
                if (!hasMultipleServices && !this.search_filter.fields.service_id){
                    this.mutateSearchFilterFieldsServiceId(this.services_by_provider[0].id);
                }
                if (hasMultipleTimes) {
                    this.initAvailabilityDrawer(this.availability.business.id) 
                } 
                else {
                    if (hasMultipleServices) {
                        this.initServiceDrawer(this.availability);
                    } else {
                        this.initBooking({availability_id: this.availability.availability_id});
                    }
                }
            }
        },
     
    },

}
</script>
<style lang="scss" scoped>

.c-salon-preview-card {
    transition: 0.4s ease;
}

.c-salon-preview-card > div {
    transition: 0.4s ease;
}

.c-salon-preview-card:hover {
    transform: scale(1.05);
    z-index: 1;
}

.c-salon-preview-card:hover > div {
    box-shadow: 0 0 10px 6px rgba(169, 169, 169, 0.3);
}

.favourite-btn {
    @apply absolute text-white;
    top: 1rem;
    right: 1rem;

    &:focus {
        outline: none;
    }

    &::before {
        @apply absolute top-0 text-white text-xs capitalize bg-black p-1 pointer-events-none whitespace-no-wrap opacity-0;
        content: "Add to favourites";
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -100%);
        border-radius: 5px;
        transition: 0.4s ease;
    }

    &--active {
        &::before {
            content: "Remove from favourites";
        }
    }

    &:hover {
        &::before {
            @apply opacity-100;
        }
    }
}



.text-102 {
    color: rgb(102, 102, 102);
}

.border-bottom:not(:last-of-type) {
    border-bottom: 1px solid lightgrey;
}
.services-gradient {
    position: relative;
}

.services-gradient::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    background: linear-gradient(to top, #fffc, #fff2 20%, #fff0 50%);
}

.availability-spacer {
    padding-top: calc(1rem + 14.5px);
    padding-bottom: calc(1rem + 14.5px);
}
</style>
