var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vue-simple-suggest",
      class: [
        _vm.styles.vueSimpleSuggest,
        { designed: !_vm.destyled, focus: _vm.isInFocus }
      ],
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
          ) {
            return null
          }
          _vm.isTabbed = true
        }
      }
    },
    [
      _c(
        "div",
        {
          ref: "inputSlot",
          staticClass: "input-wrapper",
          class: _vm.styles.inputWrapper,
          attrs: {
            role: "combobox",
            "aria-haspopup": "listbox",
            "aria-owns": _vm.listId,
            "aria-expanded":
              !!_vm.listShown && !_vm.removeList ? "true" : "false"
          }
        },
        [
          _vm._t("default", [
            _c(
              "input",
              _vm._b(
                {
                  staticClass: "default-input",
                  class: _vm.styles.defaultInput,
                  domProps: { value: _vm.text || "" }
                },
                "input",
                _vm.$attrs,
                false
              )
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "vue-simple-suggest" } }, [
        !!_vm.listShown && !_vm.removeList
          ? _c(
              "ul",
              {
                staticClass: "suggestions",
                class: _vm.styles.suggestions,
                attrs: {
                  id: _vm.listId,
                  role: "listbox",
                  "aria-labelledby": _vm.listId
                },
                on: {
                  mouseenter: function($event) {
                    return _vm.hoverList(true)
                  },
                  mouseleave: function($event) {
                    return _vm.hoverList(false)
                  }
                }
              },
              [
                !!this.$scopedSlots["misc-item-above"]
                  ? _c(
                      "li",
                      [
                        _vm._t("misc-item-above", null, {
                          suggestions: _vm.suggestions,
                          query: _vm.text
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.suggestions, function(suggestion, index) {
                  return _c(
                    "li",
                    {
                      key: _vm.getId(suggestion, index),
                      staticClass: "suggest-item",
                      class: [
                        _vm.styles.suggestItem,
                        {
                          selected: _vm.isSelected(suggestion),
                          hover: _vm.isHovered(suggestion)
                        }
                      ],
                      attrs: {
                        role: "option",
                        "aria-selected":
                          _vm.isHovered(suggestion) ||
                          _vm.isSelected(suggestion)
                            ? "true"
                            : "false",
                        id: _vm.getId(suggestion, index)
                      },
                      on: {
                        mouseenter: function($event) {
                          return _vm.hover(suggestion, $event.target)
                        },
                        mouseleave: function($event) {
                          return _vm.hover(undefined)
                        },
                        click: function($event) {
                          return _vm.suggestionClick(suggestion, $event)
                        }
                      }
                    },
                    [
                      _vm._t(
                        "suggestion-item",
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.displayProperty(suggestion)))
                          ])
                        ],
                        {
                          autocomplete: function() {
                            return _vm.setText(_vm.displayProperty(suggestion))
                          },
                          suggestion: suggestion,
                          query: _vm.text
                        }
                      )
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                !!this.$scopedSlots["misc-item-below"]
                  ? _c(
                      "li",
                      [
                        _vm._t("misc-item-below", null, {
                          suggestions: _vm.suggestions,
                          query: _vm.text
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }