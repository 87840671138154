<template>
    <div class="c-overlay fixed inset-0" v-if="overlay.visibility" :class="{'c-overlay--no-shadow' : overlay.modal.modifierClass == 'c-overlay__modal--full-screen'}">
        <div class="c-overlay__modal" :class="overlay.modal.modifierClass">
            <component :is="overlay.modal.content" class="pt-10 pt-4 p-4"></component>
            
            <span v-if="overlay.modal.modifierClass != 'c-overlay__modal--full-screen'" @click="mutateOverlayModalModifierClass(null) && mutateOverlayVisibility(false)" class="c-overlay__close" role="button" aria-label="Close popup"><span class="relative" style="top:-2px;">&times;</span></span>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    data() {
        return {

        }
    },

    computed: {
        ...mapState(['overlay'])
    },

    methods: {
        ...mapMutations(['mutateOverlayVisibility', 'mutateOverlayModalModifierClass'])
    },

    created() {
        document.onkeydown = e => {
            e = e || window.event;
            if (e.keyCode == 27) {
                this.mutateOverlayModalModifierClass(null);
                this.mutateOverlayVisibility(false)
            }
        };
    }
}
</script>

<style lang="scss" scoped>
    .c-overlay {
        z-index: 99;

        &:not(.c-overlay--no-shadow) {
            background: rgba(0,0,0,0.8);
        }

        &__modal {
            @apply bg-white rounded-lg;

            &:not(.c-overlay__modal--full-screen) {
                @apply absolute;
                top: 50%;
                left: 50%;
                z-index: 100;
                transform: translate(-50%, -50%);
            }

            > div {
                // @apply p-4;
                @apply h-full w-full overflow-auto;
                width: calc(100vw - 2rem);
                max-height: 80vh;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style: -ms-autohiding-scrollbar;

                @screen lg {
                    width: 600px;
                }
            }

            &--large {
                > div {
                    height: 90vh;
                    @screen lg {
                        width: 900px;
                    }
                }
            }

            &--xl {
                > div {
                    // @screen lg {
                    //     width: 900px;
                    // }

                    // @screen xl {
                    //     width: 1200px;
                    // }

                    // @screen 2xl {
                    //     width: 1300px;
                    // }
                    height: calc(100vh - 4rem);
                    width: calc(100vw - 4rem);
                }
            }

            &--customer-app-notif {
                > div {
                    @screen lg {
                        width: 800px;
                    }
                }
            }
            &--right-place-notif {
                height: 800px;

                @screen md {
                    height: 400px;
                }

                > div {
                    height: 800px;
                    max-height: unset;
    
                    @screen md {
                        height: 400px;
                    }
                    @apply rounded-lg overflow-hidden;

                    // min-height: 400px;
                    @screen lg {
                        width: 400px;
                    }
                    padding: 0;

                    > div {
                        height: 400px;
                    }
                }

                .c-overlay__close {
                    box-shadow: none;
                    @apply bg-copper-400 text-white;

                    &:hover {
                        @apply shadow-lg-allsides;
                    }
                }
            }

            &--full-screen {
                @apply bg-teal-300 h-screen overflow-y-auto;
                border-radius: 0;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style: -ms-autohiding-scrollbar;

                @supports (height: -webkit-fill-available) {
                    height: -webkit-fill-available;
                }

                @supports (height: -moz-available) {
                    // height: -moz-available
                    @apply h-screen;
                }

                > div {
                    @apply w-screen flex flex-col justify-center overflow-visible h-auto;
                    max-height: none;


                    @supports (height: -moz-available) {
                        @apply mb-12;
                    }
                }
            }

            ::v-deep h1 {
                @media(max-width: 767px) {
                    font-size: 1.75rem;
                }
            }
        }

        &__close {
            @apply absolute top-0 flex items-center justify-center bg-white shadow-lg-allsides font-light text-3xl rounded-full;
            right: 50%;
            transform: translate(50%, -50%);
            width: 40px;
            height: 40px;
            transition: 0.4s ease;

            @screen md {
                @apply right-0;
                width: 30px;
                height: 30px;
            }

            &:hover {
                transform: translate(50%, -50%) scale(1.05);
            }
        }
    }
</style>
