var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ((_obj = { active: _vm.shouldShow }),
      (_obj[_vm.className] = true),
      _obj)
    },
    [
      _c(
        "div",
        {
          staticClass: "flex justify-between cursor-pointer",
          on: {
            click: function($event) {
              return _vm.toggleOpen()
            }
          }
        },
        [
          _c("h3", { staticClass: "c-accordion__title font-averta" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "c-accordion-alt__toggle pl-4 text-grey-800 text-base font-normal underline hover:no-underline cursor-pointer",
              attrs: { role: "button" }
            },
            [_vm._v(_vm._s(_vm.shouldShow ? "Hide" : "Show"))]
          )
        ]
      ),
      _vm._v(" "),
      _vm.shouldShow
        ? _c(
            "div",
            {
              staticClass: "c-accordion-alt__content",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }