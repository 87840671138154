<template>
    <div class="container md:w-auto mx-auto">
        
        <div class="flex flex-col-reverse sm:flex-col md:flex-row md:justify-between flex-wrap md:items-start z-index-20">
            <template v-if="selected_view_mode === 'all'">
                <div class="flex flex-col mb-12">
                    <h2  class="mb-2 mt-1 hidden sm:block">
                        All Availability
                    </h2>
                    <!-- CATEGORY PICKER -->
                    <div class="categories flex flex-row flex-wrap">
                        <span v-for="category in provider_categories" :key="`category-${category.id}`" @click="selectCategory(category)" 
                            class="category-badge mr-2 mb-2 bg-grey-200 font-medium text-sm  px-2 py-1 cursor-pointer"
                            :class="{'selected': !selected_industry_ids.length || selected_industry_ids.includes(category.id)}"
                        >
                            {{category.name}}
                        </span>
                    </div>
                </div>
            </template>

            <!-- SELECTED PROVIDER -->
            <div v-else-if="selected_provider" class="flex flex-row provider w-full lg:w-1/2 mb-12">
                <div class="bg-grey-300 rounded-full overflow-hidden hidden sm:block mr-6 mb-2 image-wrapper">
                    <a :href="`/business/${business.slug}/seat-${selected_provider.id}/availability`">
                        <img :src="selected_provider.profile_image"  class="rounded-full" />
                    </a>
                </div>
                <div class="flex flex-col justify-center mt-6 sm:mt-0">
                    <span v-if="isProviderAvailable(selected_provider)" class="text-copper-400 font-semibold">I'M AVAILABLE</span>
                    <span v-else class="text-grey-350 font-semibold">NO AVAILABILITY</span>

                    <h2 class="mb-2">{{selected_provider.full_name}}</h2>

                    <!-- CATEGORY PICKER -->
                    <div class="categories flex flex-row flex-wrap">
                        <span v-for="category in provider_categories" :key="`category-${category.id}`" @click="selectCategory(category)" 
                            class="category-badge mr-2 mb-2 bg-grey-200 hover:bg-grey-400 font-medium text-sm hover:text-white px-2 py-1 cursor-pointer"
                            :class="{'selected': !selected_industry_ids.length || selected_industry_ids.includes(category.id)}"
                        >
                            {{category.name}}
                        </span>
                    </div>
                </div>
            </div>
            <h2 v-else class="mt-1 hidden sm:block">
                Select By Professional
            </h2>
            <div class="h-full sm:ml-auto mb-2">
                <select v-model="view_mode" class="c-form__select mb-4 w-full sm:w-auto">
                    <option value="all" >Showing All Availability</option>
                    <option value="by_professional" default>Showing Availability by Professional</option>
                </select>
            </div>
        </div>

        <!-- AVAILABILITY LIST -->
        <div v-if="selected_view_mode==='all' || selected_provider" class="flex flex-col">
            <div v-for="(day, index) in Object.keys(category_availabilities)" :key="`availability-day-${day}`"
                class=""
            >
                <generic-accordion class="avail-accordian" v-if="category_availabilities[day].length" :title="category_availabilities[day][0].day_word.toUpperCase()" :accordion-index="index" :show="index === accordian_index" @Open="accordian_index = accordian_index === index ? null :index">
                    <div v-for="(availability) in category_availabilities[day]" :key="`availability-info-${availability.id}`" 
                        class="w-full mt-12 first:mt-6 "
                    >
                        <!-- <div> -->
                            <!-- <p class="label mb-0">{{ availability.day_word }}</p> -->
                            <p class="text-lg font-poynter font-bold text-copper-400 mb-0 ml-2">{{ getFormattedTime(availability.availability_start) }} - {{ getFormattedTime(availability.availability_end) }}</p>
                            <p v-if="!selected_provider_id" class="font-poynter font-bold text-base mb-0 ml-2">
                                With 
                                <a class="underline hover:no-underline" :href="getUrl(availability)">{{ availability.user.full_name }}</a>
                            </p>

                            <table class="mt-6 c-stylised-table condensed w-full">
                                <tr v-for="service in availability.services.slice(0, show_more[`availability-service-${availability.id}-${selected_provider_id}`] ? availability.services.length : 5)" :key="`availability-service-${availability.id}-${service.id}`">
                                    <td class="py-2 px-2 sm:px-4">
                                        <strong class="mr-2">{{ service.name }}</strong>
                                        <span>{{ service.time_required_for_humans }}</span>
                                    </td>
                                    <!-- <td class="text-right">
                                    </td> -->
                                    <td class="text-right py-2 px-2 sm:px-4">
                                        <span class="font-semibold"> {{service.pivot.cost ? toCurrency(service.pivot.cost/100) : 'Free'}}</span>
                                        <a @click.stop="onClick(availability, service)" class=" ml-4 underline hover:no-underline cursor-pointer">Book</a>
                                    </td>
                                </tr>
                            </table>
                            <a v-if="availability.services.length > 5" @click.stop="toggleShowMore(availability)" class="pl-4 text-grey-800 text-base font-normal underline hover:no-underline mt-2 cursor-pointer"> {{show_more[`availability-service-${availability.id}-${selected_provider_id}`] ? 'Show Less' : 'Show More'}}</a>
                        <!-- </div> -->
                    </div>
                    <!-- {{day}} -->
                </generic-accordion>
            </div>
        
        </div>
        <div v-if="loaded && !Object.keys(category_availabilities).length" class="col w-full text-center">
            <img src="/images/headshots/no-results.png" aria-hidden="true" class="no-results-img mx-auto">
            <h2 class="mb-2">Oh no!</h2>
            <p class="mb-0 text-black text-xl">
                It looks like {{selected_provider ? `${selected_provider.full_name} is` : 'our businesses are'}} busy at these times.<br>
            </p>
        </div> 

        <!-- SELECT PROVIDER -->
        <div v-if="selected_view_mode === 'by_professional' && !selected_provider" class="flex flex-col items-center mx-auto justify-start md:items-start content-start md:justify-start md:flex-row flex-wrap">
            <div v-for="provider in providers" :key="`provider-${provider.id}`"
                class="flex flex-col items-center mr-auto sm:flex-row provider in-list mt-12 w-full xl:w-1/3 md:w-1/2"
            >
                <div class="bg-grey-300 rounded-full overflow-hidden mr-0 sm:mr-6 mb-2 image-wrapper">
                    <a :href="`/business/${business.slug}/seat-${provider.id}/availability`">
                        <img :src="provider.profile_image"  class="rounded-full" />
                    </a>
                </div>
                <div class="flex flex-col justify-center text-center sm:text-left ">
                    <span v-if="isProviderAvailable(provider)" class="text-copper-400 font-semibold">I'M AVAILABLE</span>
                    <span v-else class="text-grey-350 font-semibold">NO AVAILABILITY</span>

                    <span class="text-black font-bold text-xl font-poynter">{{provider.full_name}}</span>
                    <span class="text-grey-800 font-semibold">{{provider.services_count}} services</span>
                    <a class="text-grey-800 font-normal underline hover:no-underline mt-2 cursor-pointer" :hreeef="getUrl({user: provider})"
                        @click="selectProvider(provider)"
                    >
                        Click to see more
                    </a>
                </div>
            </div>
        </div>

        <!-- LOAD MORE -->
        <div ref="more_btn" class="w-full mt-6 flex-row flex mb-6 justify-center">
            <div v-if="(view_mode === 'all' || selected_provider) && can_load_more" 
                @click="getAvailability(current_page +1)"
                class="px-4 md:px-8 mx-auto py-2 bg-teal-400 hover:bg-teal-600 cursor-pointer rounded-md text-black uppercase font-bold inline-block whitespace-nowrap"
            >
                Show More Availability
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapMutations } from 'vuex';
import {cloneDeep} from 'lodash';
import { LIVE_SEARCH_SERVICES, LIVE_SEARCH_INDUSTRIES, LIVE_SEARCH_CITIES, LIVE_SEARCH_BUSINESS, LIVE_SEARCH_GEOLOCATION } from '@js/endpoints';
// const clone = (items) => items.map(item => Array.isArray(item) ? clone(item) : item);
export default {
    props: ['all_availabilities', 'business', 'providers', 'preselected_provider', 'preselected_viewmode', 'providermode'],
    data() {
        return {
            view_mode: 'by_professional',
            provider_id: null,
            industry_id: null,
            industry_ids: [],
            categories: [],
            show_more: {},
            new_availabilities: [],
            can_load_more: false,
            current_page: null,
            accordian_index: null,
            loaded: false,
        }
    },
    mounted(){ 
        if (window.location.search.includes('?all')) this.view_mode = 'all';
        axios.get(`${LIVE_SEARCH_INDUSTRIES}`).then(res => {
            this.categories = res.data.data;
        });
        let url = `api/v1/availability?business_id=${this.business.id}`;
        // if (this.)
    },
    computed: {
        selected_provider_id() {
            return this.preselected_provider || this.provider_id
        },
        selected_industry_ids() {
            return this.industry_ids
        },

        selected_view_mode() {
            return this.preselected_viewmode || this.view_mode;
        },
        provider_availabilities() {
            let availabilities = this.new_availabilities;
            let the_avails = {};
            Object.keys(availabilities).forEach(day => {
                let avails = availabilities[day];
                if (this.selected_provider_id) {
                    avails = avails.filter(avail => avail.user.id === this.selected_provider_id);
                }
                the_avails[day] = avails;
            })
            return the_avails;
        },

        category_availabilities() {
            if (!this.selected_industry_ids.length)  return this.provider_availabilities;
            let availabilities = cloneDeep(this.provider_availabilities);
            console.log(availabilities);
            if (this.selected_industry_ids.length) {
                Object.keys(availabilities).forEach(day => {
                    if (! availabilities[day].length) delete availabilities[day];
                    availabilities[day] = availabilities[day].filter(avail => avail.services.find(service => this.selected_industry_ids.includes(service.industry_id)))
                    .map(avail => {
                        avail.services = avail.services.filter(service => this.selected_industry_ids.includes(service.industry_id));
                        return avail;
                    });
                })
                // availabilities = availabilities.filter(avail => avail.services.find(service => this.selected_industry_ids.includes(service.industry_id)))
                //     .map(avail => {
                //         avail.services = avail.services.filter(service => this.selected_industry_ids.includes(service.industry_id));
                //         return avail;
                //     });
            }

            return availabilities;
        },

        selected_provider() {
            return this.providers.find(provider => provider.id === this.selected_provider_id)
        },

        provider_categories() {
            if (!this.provider_availabilities) return [];
            return this.categories.filter(category => {
                return Object.values(this.provider_availabilities).find(day => {
                    return day.find(avail => avail.services.find(service => service.industry_id === category.id) ? true : false);
                }) ? true : false;
            })
        }
    },
    methods: {
        ...mapActions([
            'initBooking'
        ]),
        ...mapMutations([
            'mutateSearchFilterFieldsServiceId'
        ]),
        getUrl(item) {
            let slug = this.business.slug;
            let provider = item.user.id;
            return `/business/${slug}/seat-${provider}/availability`;
        },

        toggleShowMore(availability) {
            let value = this.show_more[`availability-service-${availability.id}-${this.selected_provider_id}`];
            this.$set(this.show_more, `availability-service-${availability.id}-${this.selected_provider_id}`, !value);
        },

        getFormattedTime(time) {
            return moment.utc(time).format("h:mmA");
        },

        isProviderAvailable(provider) {
            if (!provider.has_availability !== undefined) return provider.has_availability;
            return Object.values(this.new_availabilities).find(day => day.find(avail => avail.user.id === provider.id)) ? true : false;
            // return Object.values(this.new_availabilities).filter(availability => availability.user.id === provider.id).length;
            // return provider.id % 2 === 0 ? true : false;
        },

        selectProvider(provider) {
            window.location.href = `/business/${this.business.slug}/seat-${provider.id}/availability`
            // this.provider_id = provider.id;
            // this.industry_id = null;
        },


        // EVENTS
        onClick(availability, service) {
            this.mutateSearchFilterFieldsServiceId(service.id); 
            this.initBooking({availability_id: availability.id})
        },

        selectCategory(category) {
            let index = this.industry_ids.indexOf(category.id);
            if (index > -1) {
                this.industry_ids.splice(index, 1);
            } else {
                this.industry_ids.push(category.id);
            }
        },

        getAvailability(page = 1) {
            if (page === 1) this.loaded = false;
            let url =  '';
            if (this.selected_provider_id) {
                url = `/api/v1/availability?page=${page}&business_id=${this.business.id}&provider_id=${this.selected_provider_id}`;

            } else {
                url = `/api/v1/availability?page=${page}&business_id=${this.business.id}`;
            }
            axios.get(url).then(response => {
                this.loaded = true;
                if (page > 1) {
                    this.new_availabilities = {...this.new_availabilities, ...response.data.data};
                    // this.$nextTick(() => {
                    //     this.$refs.more_btn.scrollIntoView();
                    // })
                }
                else {
                    this.new_availabilities = response.data.data;
                }
                this.current_page = response.data.current_page;
                this.can_load_more = response.data.last_page > this.current_page;
            });
        }
    },

    watch: {
        selected_view_mode(new_value) {
            if (new_value === 'all') {
                
                this.provider_id = null;
                this.industry_id = null;
            }
        },

        view_mode(new_value) {
            if (new_value === 'all') {
                if (this.providermode === true) {
                    console.log("providermode");
                    window.location.href=`/business/${this.business.slug}/availability?all`;
                }
            }
        },

        selected_provider_id: {
            handler: function (new_id) {
                this.getAvailability();
            },
            immediate: true,


        }
    }
}
</script>
<style lang="scss" scoped>
.avail-accordian {
    &:not(.active) {
    }
    padding: 20px 0;
    border-bottom: 1px solid lightgrey ;
    // background-color: red;
}

.provider > .image-wrapper, .provider > .image-wrapper > a> img  {
    width: 150px;
    min-width: 150px;
    height: 150px;
}

.provider.in-list {
    max-width: 400px;
}

.category-badge {
    height: 30px;
    border-radius: 15px;
    @media (pointer: fine) {
        &:hover {
            @apply bg-grey-400 text-white
        }

    }
    

    &.selected {
        @apply bg-teal-400 text-black;
        @media (pointer: fine)  {
            &:hover {
                @apply bg-teal-600;
            }

        }
    }
}

select {
    width: 100%;
    // overflow: hidden;
    // white-space: pre;
    text-overflow: ellipsis;
}

.wrapper {
    @screen sm {
        max-width: 640px;
    }
    @screen md {
        max-width: 788px;
    }
    @screen lg {
        max-width: 1024px;
    }
    @screen xl {
        max-width: 1280px;
    }
    @screen 2xl {
        max-width: 1536px;
    }
}

</style>
