<template>
    <div v-on-clickaway="() => mutateOverlayVisibility(false)">
        <div class="flex flex-wrap items-center items-stretch p-0 right-place">
            <div class="w-full text-center p-16 text-white flex flex-col items-center justify-evenly h-400 bg-copper-400">
               <span class="font-semibold">BUSINESS</span>
               <span class="-mx-1 font-poynter text-2xl">I am a business and I want to know more</span>
                <a class="btn btn--dark-grey px-8 text-center uppercase" @click="goToBusiness">I'm a business</a>

            </div>

        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import moment from "moment";

export default {
    data() {
        return {
            current_url: window.location.pathname,
        }
    },

    computed: {
        ...mapState({
            overlay: state =>  state.overlay,
        }),
    },

    mounted() {
        $cookies.set('has_been_served_right_place_popup', true, moment().add(1, 'months')._d);
    },

    methods: {
        ...mapMutations(['mutateOverlayVisibility']),

        returnOS() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent)) {
                return "android";
            }else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "ios";
            }else {
                return "unknown";
            }
        },
        goToCustomer() {
            if (window.location.pathname === '/' || window.location.pathname === '/#' ) {
                this.mutateOverlayVisibility(false);
            } else {
                window.location.href = '/#'
            }
        },
        goToBusiness() {
            
            if (window.location.pathname.includes('/businesses')) {
                this.mutateOverlayVisibility(false);
            } else {
                window.location.href = '/businesses/#'
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.right-place {
    height: 800px;

    >div {
        @apply p-8;
    }
    @screen md {
        height: 400px;
        >div {
            @apply p-16;
        }
    }
}


.h-400 {
    height: 400px;
}
.btn {
    @apply px-8;
}
</style>
