<template>
    <div :class="{ active: shouldShow, [className]: true }"  @click="mutateActiveAccordionIndex(accordionIndex)">
        <div class="flex justify-between">
            <h3 class="c-accordion__title">{{ title }}</h3>
            <div class="c-accordion__toggle" role="button"></div>
        </div>
        <div v-if="shouldShow" class="c-accordion__content">
          <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    data() {
        return {
            
        }
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        accordionIndex: {
            type: Number,
            required: true,
        },
        className: {
            type: String,
            default: "c-accordion"
        }
    },

    computed: {
        ...mapState(['misc']),

        shouldShow() {
            return this.accordionIndex == this.misc.accordionActive;
        }
    },

    methods: {
        ...mapMutations(['mutateActiveAccordionIndex'])
    }

}
</script>

<style lang="scss" scoped>
    .c-accordion {
        @apply p-4 border-2 border-solid border-grey-200 rounded-lg relative mt-6 p-8 pr-10 cursor-pointer;
        transition: 0.4s ease;

        &.active {
            @apply bg-grey-200;
            box-shadow: 0 0 5px 5px rgba(169, 169, 169, 0.3);

            .c-accordion__toggle {
                transform: rotate(180deg);
            }
        }

        &__title {
            @apply mb-0 mr-4;
        }
        
        &__toggle {
            @apply cursor-pointer bg-no-repeat bg-center;
            height: 30px;
            width: 30px; 
            background-size: 20px;
            background-image: url('/images/icons/arrow.svg');
            transition: 0.4s ease;

            @screen md {
                background-size: 25px;
            }
        }

        &__content {
            @apply pt-6;
        }
    }

</style>
