<template>
    <div class="relative flex items-center">
        <div class="c-filter-option">
            <div class="c-form__checkbox-as-toggle">
                <input type="checkbox" :id="selected" v-model="search_filter.fields[selected]" :true-value="1" :false-value="0">
                <label :for="selected">{{ selected_label }}</label>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        selected: {
            type: String //refers to $store search_filter.fields.XXXX
        },

        options: {
            type: String //refers to $store search_filter.options.XXXX
        }
    },

    computed: {
        ...mapState(['search_filter']),
        selected_label() {
            return this.search_filter.options[this.options].find(item => item.value == this.search_filter.fields[this.selected]).label;
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-filter-option {
        @apply border-none;
    }
</style>
