<template>
    <div v-on-clickaway="() => mutateOverlayVisibility(false)">
        <div class="flex flex-wrap items-center lg:px-6 md:pb-6 md:-mx-4">
            <div class="w-full md:w-7/12 px-4">
                <img src="/images/app-available-popup/admin-script-text.svg" alt="Our app is now live!" class="mb-4 inline-block mx-auto md:mx-0">
                <p class="text-copper-400 text-2xl md:text-3xl lg:text-4xl leading-tight font-poynter text-center md:text-left mb-6 w-full md:w-11/12 lg:w-full">Making it even easier to book your last minute appointments</p>
                <div class="flex justify-center md:justify-start items-start mb-8 md:mb-0">
                    <a v-if="returnOS() == 'ios' || returnOS() == 'unknown'" href="https://apps.apple.com/gb/app/eleventh-hour-beauty/id1533253533" target="_blank">
                        <img src="/images/app-available-popup/app-store.svg" alt="Download from the App Store" class="w-full">
                    </a>
                    <a v-if="returnOS() == 'android' || returnOS() == 'unknown'" href="https://play.google.com/store/apps/details?id=little.eleventh_hour.eleventh_hour_beauty" target="_blank">
                        <img src="/images/app-available-popup/google-store.svg" alt="Download from Google Play Store" class="w-full">
                    </a>
                </div>
            </div>
            <div class="w-full md:w-5/12 px-4 flex justify-center">
                <img src="/images/app-available-popup/users-image.svg" alt="" aria-hidden="true" class="w-full">
            </div>

        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import moment from "moment";

export default {
    data() {
        return {
        }
    },

    computed: {
        ...mapState({
            overlay: state =>  state.overlay,
        }),
    },

    mounted() {
        $cookies.set('has_been_served_customer_app_available_popup', true, moment().add(1, 'months')._d);
    },

    methods: {
        ...mapMutations(['mutateOverlayVisibility']),

        returnOS() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent)) {
                return "android";
            }else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "ios";
            }else {
                return "unknown";
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
