var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-popup-drawer" }, [
    _c("div", { staticClass: "c-popup-drawer__inner" }, [
      _c(
        "div",
        [
          _c(
            "button",
            {
              staticClass: "c-drawer-close",
              attrs: { "aria-label": "Close" },
              on: {
                click: function($event) {
                  _vm.booker.selected.stage == "processed"
                    ? _vm.dismiss()
                    : _vm.initCancelProcedure()
                }
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "c-drawer-close__icon",
                  staticStyle: { top: "-2px" }
                },
                [_vm._v("×")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "c-drawer-close__tooltip" }, [
                _vm._v(
                  _vm._s(
                    _vm.booker.selected.stage == "processed"
                      ? "Dismiss "
                      : "Cancel Booking?"
                  )
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm.booker.selected.stage == "booking" && _vm.booker.details
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "row row--tight",
                    staticStyle: { margin: "0" }
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full lg:w-6/12 xl:w-8/12 col text-center pt-4 lg:pt-0 my-4 lg:my-0 border-t border-b lg:border-none"
                      },
                      [
                        _c("p", { staticClass: "mb-0" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.booker.details.provider.business.name)
                            )
                          ]),
                          _vm._v(" with "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.booker.details.provider.full_name)
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "mb-2" }, [
                          _vm._v(_vm._s(_vm.availability_date))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "xl:flex xl:justify-center flex-wrap"
                          },
                          [
                            _c("div", [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "hidden md:block xl:inline-block text-left"
                                },
                                [_vm._v("Request")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.booker.selected.service,
                                      expression: "booker.selected.service"
                                    }
                                  ],
                                  staticClass:
                                    "c-form__select w-full xl:w-auto",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.booker.selected,
                                        "service",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.booker.details.services, function(
                                  service,
                                  index
                                ) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      domProps: { value: service }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(service.name) +
                                          " - " +
                                          _vm._s(service.cost) +
                                          "*"
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-left" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "hidden md:block xl:inline-block xl:mx-2"
                                },
                                [_vm._v("At")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.booker.selected.time,
                                      expression: "booker.selected.time"
                                    }
                                  ],
                                  staticClass: "c-form__select w-auto",
                                  staticStyle: { "min-width": "auto" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.booker.selected,
                                        "time",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.times, function(time, index) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      domProps: { value: time.value }
                                    },
                                    [_vm._v(_vm._s(time.label))]
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _vm.booker.selected.service &&
                              _vm.booker.selected.time
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "whitespace-nowrap md:ml-2"
                                    },
                                    [
                                      _vm._v(
                                        "Finishing at: " +
                                          _vm._s(_vm.finishing_time)
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "w-full lg:w-3/12 xl:w-2/12 col text-right"
                      },
                      [
                        _vm.booker["selected"]["time"] &&
                        _vm.booker["selected"]["service"]
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn--green btn--arrow",
                                on: {
                                  click: function($event) {
                                    _vm.booker.selected.stage = _vm.provider_is_mobile
                                      ? "location"
                                      : "confirming"
                                  }
                                }
                              },
                              [
                                _vm._v("\n                            Next "),
                                _c("i", { staticClass: "fas fa-chevron-right" })
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "c-drawer-timer",
                    class: _vm.time_remaining_traffic_light
                  },
                  [
                    _c("div", { staticClass: "c-drawer-timer__icon" }, [
                      _c("i", { staticClass: "far fa-stopwatch text-lg" }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.time_until_expire))])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "c-drawer-timer__tooltip" }, [
                      _vm._v("To complete your booking")
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.booker.selected.stage == "location"
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "row row--tight",
                    staticStyle: { margin: "0" }
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full lg:w-6/12 xl:w-8/12 col text-center pt-4 lg:pt-0 my-4 lg:my-0 border-t border-b lg:border-none font-bold"
                      },
                      [
                        _vm._v(
                          "\n                        Confirm or update your home address\n                        "
                        ),
                        _c(
                          "div",
                          { staticClass: "xl:flex xl:justify-center" },
                          [
                            _c(
                              "div",
                              {
                                ref: "fake_textarea",
                                staticClass:
                                  "focus:outline-none bg-gray-200 p-4 w-full my-2 rounded-md text-center font-normal",
                                attrs: { contenteditable: "" },
                                on: { input: _vm.onLocationInput }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.current_user_address) +
                                    "\n                            "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(2)
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "w-full lg:w-3/12 xl:w-2/12 col text-right"
                      },
                      [
                        _vm.booker["selected"]["time"] &&
                        _vm.booker["selected"]["service"]
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn--green btn--arrow",
                                on: {
                                  click: function($event) {
                                    _vm.booker.selected.stage = "confirming"
                                  }
                                }
                              },
                              [
                                _vm._v("Next "),
                                _c("i", { staticClass: "fas fa-chevron-right" })
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "c-drawer-timer",
                    class: _vm.time_remaining_traffic_light
                  },
                  [
                    _c("div", { staticClass: "c-drawer-timer__icon" }, [
                      _c("i", { staticClass: "far fa-stopwatch text-lg" }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.time_until_expire))])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "c-drawer-timer__tooltip" }, [
                      _vm._v("To complete your booking")
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.booker.selected.stage == "confirming"
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "row row--tight",
                    staticStyle: { margin: "0" }
                  },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full lg:w-6/12 xl:w-8/12 col text-center mb-8 lg:mb-0 py-4 lg:py-0 my-4 lg:my-0 border-t border-b lg:border-none"
                      },
                      [
                        _c("p", { staticClass: "mb-2" }, [
                          _vm._v(
                            "\n                            You are about to book \n                            "
                          ),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.booker.selected.service.name))
                          ]),
                          _vm._v("\n                            with "),
                          _c("br", { staticClass: "hidden md:inline" }),
                          _vm._v(" "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.booker.details.provider.business.name)
                            )
                          ]),
                          _vm._v(" \n                            by "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.booker.details.provider.full_name)
                            )
                          ]),
                          _vm._v(" \n                            at "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.selected_time_pretty))
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.times.length > 1
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "underline hover:no-underline text-grey-400",
                                attrs: { role: "button" },
                                on: {
                                  click: function($event) {
                                    _vm.booker.selected.stage = "booking"
                                  }
                                }
                              },
                              [_vm._v("Change Details")]
                            )
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "w-full lg:w-3/12 xl:w-2/12 col text-right"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn--green btn--arrow",
                            on: { click: _vm.initConfirmProcedure }
                          },
                          [
                            _vm._v("Confirm "),
                            _c("i", { staticClass: "fas fa-chevron-right" })
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "c-drawer-timer lg:mt-4",
                    class: _vm.time_remaining_traffic_light
                  },
                  [
                    _c("div", { staticClass: "c-drawer-timer__icon" }, [
                      _c("i", { staticClass: "far fa-stopwatch text-lg" }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.time_until_expire))])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "c-drawer-timer__tooltip" }, [
                      _vm._v("To complete your booking")
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.booker.selected.stage == "processed"
            ? [
                _vm.booker.appointment.status == "confirmed"
                  ? _c("div", { staticClass: "text-center" }, [
                      _c("h2", { staticClass: "text-copper-400 mb-2" }, [
                        _vm._v("Yay! All booked")
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "mb-2" },
                        [
                          _vm._v(
                            "We'll send you an email to confirm your booking. "
                          ),
                          _vm._l(
                            Object.values(
                              _vm.booker.appointment.properties.reduce(function(
                                p,
                                properties
                              ) {
                                properties[p.id] = p
                                return properties
                              },
                              {})
                            ),
                            function(prop) {
                              return [_vm._v(_vm._s(prop.description) + " ")]
                            }
                          )
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "underline hover:no-underline focus:outline-none",
                          attrs: { href: _vm.ACCOUNT_BOOKINGS },
                          on: { click: _vm.dismiss }
                        },
                        [_vm._v("Got It")]
                      )
                    ])
                  : _c("div", { staticClass: "text-center" }, [
                      _c("h2", { staticClass: "text-copper-400 mb-2" }, [
                        _vm._v("Almost There!")
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "mb-2" },
                        [
                          _vm._v(
                            "We're waiting for " +
                              _vm._s(
                                _vm.booker.details.provider.business.name
                              ) +
                              " to confirm your booking. They may get in touch to check details. "
                          ),
                          _c("br", { staticClass: "hidden md:inline" }),
                          _vm._v(
                            "Make sure your contact details are up to date in Settings.\n                    "
                          ),
                          _vm._l(_vm.appointment_properties, function(prop) {
                            return [_vm._v(_vm._s(prop.description) + " ")]
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "underline hover:no-underline focus:outline-none",
                          on: { click: _vm.dismiss }
                        },
                        [_vm._v("Got It")]
                      )
                    ])
              ]
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "w-full lg:w-3/12 xl:w-2/12 col text-center lg:text-left"
      },
      [
        _c("h2", { staticClass: "text-copper-400 mb-0" }, [
          _vm._v("What Time?")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "w-full lg:w-3/12 xl:w-2/12 col text-center lg:text-left"
      },
      [
        _c("h2", { staticClass: "text-copper-400 mb-0" }, [
          _vm._v("Confirm Location")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "sublabel" }, [
      _vm._v("This information comes from your "),
      _c(
        "a",
        {
          staticClass: "underline hover:no-underline",
          attrs: { href: "/my-account/details" }
        },
        [_vm._v("Profile")]
      ),
      _vm._v(
        ". If you want to speed up this process in the future make sure your address is up to date."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "w-full lg:w-3/12 xl:w-2/12 col text-center lg:text-left"
      },
      [_c("h2", { staticClass: "text-copper-400 mb-0" }, [_vm._v("Confirm")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }