<template>
    <div>
        <div class="c-image-uploader__image cursor-pointer" :class="{'c-image-uploader__image--processing' : processing }" :style="background">
            <input type="file" :disabled="disabled" accept="image/*" @change="generatePreview($event); preventNavigateAway($event)" ref="upload_field" :name="name" class="w-full opacity-0 top-0 left-0 right-0 bottom-0 absolute cursor-pointer">
            <div class="absolute text-center border-gray-500 text-white rounded-full bg-grey-1000 text-white" style="bottom:10px; left:50%; transform: translatex(-50%); width:30px; height: 30px;" v-if="!disabled" :class="{'pointer-events-none': !preview}">
                <i class="fas fa-pencil-alt text-sm relative" style="top:2px;" v-if="!preview"></i>
                <i class="fal fa-trash-alt text-sm relative cursor-pointer" @click="clearField" style="top:2px;" v-if="preview"></i>
            </div>
        </div>
        <!-- <a role="button" v-if="preview" @click="clearField" class="text-center">Clear</a> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            preview: null
        }
    },

    computed: {
        background() {
            return this.preview ? `background-image: url('${this.preview}')` : (this.existing ? `background-image: url('${this.existing}')` : null);
        }
    },

    props: {
        maxFileSize: {
            type: Number,
            default: 5 //MB
        },

        name: {
            type: String,
            required: true
        },

        existing: {
            type: String,
            required: false,
            default: null
        },

        processing: {
            type: Boolean,
            required: false,
            default: false
        },

        disabled: {
            default: false
        }
    },

    methods: {
        generatePreview(e) {
            this.validFileSize(e.target.files[0]) ? this.preview = URL.createObjectURL(e.target.files[0]) : (alert('File too big!'), this.clearField());
        },

        clearField() {
            this.$refs.upload_field.value = '';
            this.preview = null;
        },

        validFileSize(file) {
            return file.size > this.maxFileSize * 1024 * 1024 ? false : true;
        },

        preventNavigateAway(e) {
            window.onbeforeunload = event => {
                event.preventDefault();
                event.returnValue = '';
            };
        }
    }
}
</script>
