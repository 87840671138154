<template>
    <div class="relative">
        <div class="c-filter-option c-form__auto-complete" :class="search_filter.fields[selected] ? ' c-filter-option--active' : null">
            <vue-simple-suggest 
                :placeholder="label" 
                :list="autocomplete.list" 
                mode="select" 
                display-attribute="name" 
                value-attribute="id" 
                :filter-by-query="true" 
                :filter="customFilter" 
                v-model="the_model" 
                :styles="autocomplete.styles" 
                :overrideFilterMethod="filterResults"
                ref="suggest"
                @blur="pickTopSuggestion"
            ></vue-simple-suggest>
            
            <span v-if="search_filter.fields[selected] && !disableClear" @click="clearSelected" class="c-filter-option__clear" role="button" aria-label="Remove this filter option">
                <i class="fal fa-times hidden md:inline-block"></i>
                <span class="inline-block md:hidden uppercase text-sm font-semibold">Edit</span>
            </span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import VueSimpleSuggest from 'vue-simple-suggest';
import Fuse from 'fuse.js';
import {filter, score} from 'fuzzaldrin';
export default {
    components: {
        VueSimpleSuggestOld: VueSimpleSuggest
    },

    data() {
        return {
            interacting: false,
            autocomplete: {
                styles: {
                    defaultInput: "c-form__input w-full"
                },
                list: []
            }
        }
    },

    props: {
        old: {
            default: false
        },
        endpoint: {
            type: String,
            required: true
        },

        endpoint_params: {
            type: Object,
            default: () => ({})
        },

        selected: {
            type: String,
            required: true
        },

        label: {
            type: String
        },

        toSetProp: {
            type: String,
            required: false,
            default: null
        },

        defaultModel: {
            default: null
        },

        disableClear: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapState(['search_filter']),

        the_model: {
            get() {
                return this.search_filter.fields[this.selected] && this.autocomplete.list.length ? this.autocomplete.list.find(item => item.id == this.search_filter.fields[this.selected] || item.id == this.search_filter.fields[this.selected].id) : this.defaultModel;
            },

            set(toSet) {

                if (toSet) {
                    this.search_filter.fields[this.selected] = this.toSetProp ? toSet[this.toSetProp] : toSet;
                }

            }
        },
    },

    methods: {
        pickTopSuggestion() {
            let top = this.$refs.suggest.$data.suggestions[0];
            console.log("TOP SUGGESTION", top);
            if (top) {
                this.the_model = top;
                this.$refs.suggest.setText(top.name);
            }
        },
        clearSelected() {
            this.search_filter.fields[this.selected] = null;
            this.$refs.suggest.setText(null);
        },

        fetchEndpoint() {
            let params = "";
            let delimiter = "?";
            Object.keys(this.endpoint_params).forEach(key => {
                let value = this.endpoint_params[key];;
                if (value) {
                    params= `${delimiter}${key}=${value}`
                    delimiter = '&';
                }
            })
            axios.get(`${this.endpoint}${params}`).then(res => {
                this.autocomplete.list = res.data.data;
            });

        },

        filterResults(results, query) {
            return filter(results, query, {key: 'name'})
        },

        customFilter(list, query) {
            let options = {
                shouldSort: true,
                threshold: 0.4,
                location: 0,
                distance: 90,
                maxPatternLength: 32,
                    minMatchCharLength: 1,
                keys: [
                    "name",
                ]
            };

            let fuse = new Fuse([list], options);
            return fuse.search(query).length ? true : false;
        }
    },

    mounted() {
        this.fetchEndpoint();
    },

    watch: {
        endpoint_params: {
            deep: true,
            handler: function () {
                this.fetchEndpoint();
                this.$refs.suggest.setText('');
            }
        },
        the_model(value) {
            if (!value)  this.$refs.suggest.setText(null);

        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .c-form__auto-complete {
        @apply mb-0;
    }
    ::v-deep .c-filter-option--active input {
        @apply bg-grey-400 text-white;

        @screen md {
            @apply bg-teal-400 text-grey-1000;
        }
    }
    ::v-deep input {
        @apply py-1 px-2 bg-white;
    }

    ::v-deep .suggestions {
        min-width: 175px;
        max-width: 310px;
    }
</style>
