const moment = require("moment");

let storage = window.localStorage;
let temp_storage = window.sessionStorage;

const key_prefix = "EH_";

const setData = (key, value, expiration=null, cookie=false) => {
    // console.log(`setData: ${key}`);
    let packet = {
        value,
        expiration
    }

    try {
        let json = JSON.stringify(packet);
        storage.setItem(`${key_prefix}${key}`, json)
        if (cookie) {
            $cookies.set(key, value, expiration ? moment.duration(expiration.diff()).asSeconds() : null)
        }
    } catch (e) {
        console.error("Issue storing localStorage data", e);
    }
}

const getData = (key, fallback=null) => {
    // console.log(`getData: ${key}`);
    try {
        let json = storage.getItem(`${key_prefix}${key}`);
        if (json) {
            let packet = JSON.parse(json);
            if (packet.expiration) {
                if ( moment().isAfter(moment(packet.expiration)) ) {
                    storage.removeItem(`${key_prefix}${key}`);
                    return fallback;
                } 
            }
            // console.log(`getData: ${key}`, packet);
            return packet.value;
        } else {
            console.warn(`getData: no localStorage data found for key: '${key}'`);
            return fallback;
        }
    } catch (e) {
        console.error(`Issue retrieving localStorage data with key: '${key}'`, e);
        return fallback;
    }
}

const removeData = (key) => {
    // console.log(`removeData: ${key}`);
    try {
        storage.removeItem(`${key_prefix}${key}`);
    } catch (e) {
        console.error(`Issue removing data with key: ${key}`, e);
    }
}

const Storage = {
    set: setData, 
    get: getData,
    remove: removeData
}; export default Storage;