var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative flex items-center" }, [
    _c("div", { staticClass: "c-filter-option" }, [
      _c("div", { staticClass: "c-form__checkbox-as-toggle" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search_filter.fields[_vm.selected],
              expression: "search_filter.fields[selected]"
            }
          ],
          attrs: {
            type: "checkbox",
            id: _vm.selected,
            "true-value": 1,
            "false-value": 0
          },
          domProps: {
            checked: Array.isArray(_vm.search_filter.fields[_vm.selected])
              ? _vm._i(_vm.search_filter.fields[_vm.selected], null) > -1
              : _vm._q(_vm.search_filter.fields[_vm.selected], 1)
          },
          on: {
            change: function($event) {
              var $$a = _vm.search_filter.fields[_vm.selected],
                $$el = $event.target,
                $$c = $$el.checked ? 1 : 0
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.search_filter.fields,
                      _vm.selected,
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.search_filter.fields,
                      _vm.selected,
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.search_filter.fields, _vm.selected, $$c)
              }
            }
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: _vm.selected } }, [
          _vm._v(_vm._s(_vm.selected_label))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }