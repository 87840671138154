<template>
    <div class="relative" v-on-clickaway="() => { interacting = false }">
        <div class="c-filter-option" :class="search_filter.fields[selected].length ? ' c-filter-option--active' : null" @click="interacting = !interacting">
            <span class="c-filter-option__text" role="button">{{ selected_label }}</span>
            <span v-if="search_filter.fields[selected].length" @click="clearSelected" class="c-filter-option__clear" role="button" aria-label="Remove this filter option">
                <i class="fal fa-times hidden md:inline-block"></i>
                <span class="inline-block md:hidden uppercase text-sm font-semibold">Edit</span>
            </span>
        </div>

        <div class="c-filter-option__drop-down" v-show="interacting">
            <div>
                <div v-for="(option, index) in search_filter.options[options]" :key="index" class="c-form__stylised-checkbox c-form__stylised-checkbox--round">
                    <input type="checkbox" :id="option.label+index" v-model="search_filter.fields[selected]" :value="option.value">
                    <label :for="option.label+index">{{ option.label }}</label>
                </div>
                <span class="text-sm text-center block" role="button" @click="clearSelected" :class="search_filter.fields[selected].length ? ' text-grey-1000 underline hover:no-underline' : null">Clear All</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            interacting: false,
            test_model: []
        }
    },

    props: {
        selected: {
            type: String //refers to $store search_filter.fields.XXXX
        },

        options: {
            type: String //refers to $store search_filter.options.XXXX
        },

        label: {
            type: String
        }
    },

    computed: {
        ...mapState(['search_filter']),

        selected_label() {

            let selected = this.search_filter.fields[this.selected];

            if (Array.isArray(selected)) {
                switch(selected.length) {
                    case 0:
                        return this.label;
                        break;

                    case 1:
                        return selected[0];
                        break;

                    case 2:
                        return `${selected[0]} & ${selected[1]}`;
                        break;

                    default:
                        let selected_ = selected;
                        return selected_.slice(0,-1).join(', ') + ` & ${selected[selected.length-1]}`;

                }
            }

            return this.label;
        },
    },

    methods: {
        clearSelected() {
            this.search_filter.fields[this.selected] = [];
        }
    }
}
</script>

<style lang="scss" scoped>
    .c-form__stylised-checkbox {
        @apply font-semibold text-base uppercase;
    }

    .c-form__stylised-checkbox label {
        @apply text-grey-400;
        transition: 0.4s ease;
    }

    .c-form__stylised-checkbox:hover label {
        @apply text-grey-1000;
    }

    .c-form__stylised-checkbox input:checked + label {
        @apply text-grey-1000;
    }
</style>
