<template>
    <div class="c-tooltip" v-on-clickaway="() => toolTipOpen = false">
        <span class="c-tooltip__trigger" @click="toolTipOpen = !toolTipOpen">
            <span v-if="triggerText" class="text-grey-1000 flex-grow-0 flex-shrink">{{ triggerText }} </span>
            <div class="relative">
                <i class="fas fa-info" style="font-size:8px;"></i>
                 <div class="c-tooltip__content" :class="{ 'c-tooltip__content--visible' : toolTipOpen }">
                    <span>{{ content }}</span>
                    <span role="button" aria-label="Close tooltip" class="c-tooltip__close" @click="toolTipOpen = false">
                        <i data-v-45bb487d="" class="far fa-times"></i>
                    </span>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            toolTipOpen: false
        }
    },

    props: {
        triggerText: {
            type: String,
            required: false
        },

        content: {
           type: String,
           required: true
       }
    },
} 
</script>

<style lang="scss" scoped>
.c-tooltip {
    @apply relative inline-block;

    &__trigger {
        @apply cursor-pointer flex flex-wrap items-center;

        i {
            @apply bg-black text-white flex items-center justify-center rounded-full ml-1 flex-shrink-0 flex-grow-0;
            width: 15px;
            height: 15px;
        }
    }

    &__content {
        @apply fixed w-screen bg-grey-1000 text-white text-center p-4 opacity-0 pointer-events-none z-10 text-sm rounded-lg;
        bottom: 1rem;
        left: 2rem;
        max-width: calc(100% - 4rem);
        transition: 0.4s ease;
        z-index: 9999999; //Has to be this high to overlap chatbot

        @screen md {
            @apply absolute;
            bottom: -10px;
            left: 50%;
            transform: translate(-50%, 100%);
            max-width: 275px;
        }

        @screen lg {
            max-width: 350px;
        }

        &::before {
            @apply absolute top-0 bg-grey-1000 hidden;
            content: "";
            width: 10px;
            height: 10px;
            left: 50%;
            transform: rotate(45deg) translateX(-50%);
            
            @screen md {
                @apply block;
            }
        }

        &--visible {
            @apply opacity-100 pointer-events-auto;
        }
    }

    &__close {
        @apply absolute top-0 right-0 bg-grey-1000 shadow-lg-allsides text-black text-lg rounded-full cursor-pointer block;
        transform: translate(50%, -50%);
        width: 25px;
        height: 25px;
        transition: 0.4s ease;

        @screen md {
            @apply hidden;
        }

        i {
            @apply absolute text-sm text-white ml-0; 
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            transform: translate(50%, -50%) scale(1.05);
        }
    }
}
</style>