<template>
    <div v-on-clickaway="() => mutateOverlayVisibility(false)">
        <h1 class="text-center">Reset Password</h1>
        <p class="px-4" v-if="success">If your email is registered on our system, you should receive a link to reset your password within the next 5 minutes.</p>
        <form class="mb-4 px-4" v-if="!success" v-on:submit.prevent="doReset">
            <p class="mb-2">Please enter your email address below.</p>
            <input type="email" name="email" class="c-form__input w-full" v-model="email" placeholder="Email Address">
            <button class="btn btn--teal text-grey-1000 w-full">Reset Password</button>
        </form>
    </div>
    <!-- <div v-on-clickaway="() => mutateOverlayVisibility(false)">
        <h1 class="text-center">Login</h1>
        <form v-on:submit.prevent="doLogin" class="mb-4 pl-4 pr-4">
            <input type="email" name="email" class="c-form__input w-full" v-model="email" placeholder="Email Address">
            <span class="c-form__inline-error" v-if="errors.email">{{ errors.email[0] }}</span>

            <input type="password" name="password" class="c-form__input w-full" v-model="password" placeholder="Password">
            <span class="c-form__inline-error" v-if="errors.password">{{ errors.password[0] }}</span>

            <button class="btn btn--teal text-grey-1000 w-full">Login</button>
        </form>

        <div class="text-center mb-10 pl-4 pr-4">
            <a :href="params.links.passwordReset" class="inline-block text-base mb-6">Forgot Your Password?</a>

            <p class="h2" style="margin-bottom:1rem;">or sign in with your social account</p>

            <div class="btn-group">
                <a href="#" class="btn btn--fb-blue">Facebook</a>
                <a href="#" class="btn btn--twitter-blue">Twitter</a>
                <a href="#" class="btn btn--insta-pink">Instagram</a>
            </div>
        </div>
        <div class="text-center bg-grey-200 p-4 rounded-b">
            <a :href="params.links.signup" class="block uppercase text-grey-1000 font-semibold text-base">Not a member? Sign up here</a>
        </div>
    </div> -->
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
    data() {
        return {
            email: null,
            password: null,
            errors: {
                email: null,
                password: null
            },

            success: false
        }
    },

    computed: {
        ...mapState({
            overlay: state =>  state.overlay,
            params: state => state.component_params.resetPassword
        })
    },

    methods: {
        ...mapMutations(['mutateOverlayVisibility']),

        doReset() {
            axios.post(this.params.endpoint, {
                email: this.email,
            }).then(res => {
                this.success = true;
            }).catch(err => {
                console.log(err);
                // if (err.response.data.errors) {
                //     this.errors = err.response.data.errors;
                // }

                // else {
                //     alert(err);
                // }
            });
        }
    },
}
</script>

<style lang="scss" scoped>
    .c-input-text {
        @apply border p-4 rounded-sm w-full mb-4;
    }
</style>
